// DishesIngSearchBar.js

import React from "react";

import * as routerHelpers from "../helpers/routerHelpers";

import "../css/DishesIngSearchBar.css";

class DishesIngSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  componentDidMount = () => {
    // Only auto-focus on text input when on a large screen.
    // This prevents on-screen keyboard from automatically
    // popping up on mobile devices.
    if (window.innerWidth >= 769) {
      this.textInput.current.focus();
    }
    window.addEventListener("resize", this.updateWidthStyle);
    this.updateWidthStyle();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWidthStyle);
  };

  // get the width of the dish page search bar so search
  // results div can be the same width
  updateWidthStyle = () => {
    const barRef = document.getElementById("dishSearchBarInput");
    const widthStyle = {
      width: barRef.clientWidth,
    };
    // console.log("widthStyle: ", widthStyle);
    this.props.updateDishSearchWidth(widthStyle);
  };

  selectIngredient = (value) => {
    // console.log(`selected: ${value}`);
    routerHelpers.addHighlightToURL(value, this.props.history);
    this.props.clearDishIngSearchResults();
  };

  handleKeyPress = (event) => {
    // console.log(event.keyCode)
    if (event.keyCode === 40) {
      // down arrow
      event.preventDefault();
      this.props.keyboardHighlightSearchSuggestionDish("DOWN");
    } else if (event.keyCode === 38) {
      // up arrow
      event.preventDefault();
      this.props.keyboardHighlightSearchSuggestionDish("UP");
    } else if (event.keyCode === 27) {
      // ESC key -> close search results
      this.props.closeDishIngSearchResults();
    } else if (event.keyCode === 13) {
      // enter
      event.preventDefault();
      console.log("enter key");
      const highlightIndex = this.props.main.highlightedSuggestionIndexDish;
      if (highlightIndex === 0) {
        // trying to hit enter on the text input itself,
        // need to confirm ingredient is in the dish.
        const cleanedInput = this.props.main.dishSearchBarInput
          .trim()
          .toLowerCase();
        if (this.props.main.ingList.includes(cleanedInput)) {
          this.selectIngredient(cleanedInput);
        }
      } else {
        // hit enter to select a search result suggestion
        const chosenIng = this.props.main.dishIngredientResults[
          highlightIndex - 1
        ];
        this.selectIngredient(chosenIng.name);
      }
    }
  };

  updateSearchTerm = (event) => {
    event.preventDefault();
    const val = event.target.value;
    this.props.updateDishSearchBarInput(val);
    // this.props.toggleDishSearchResults(true);
    this.props.updateDishIngredientSearch();
  };

  render = () => {
    let placeholder = "search ingredients";
    return (
      // <div id="dishSearchBar">
      <input
        id="dishSearchBarInput"
        type="text"
        ref={this.textInput}
        placeholder={placeholder}
        value={this.props.main.dishSearchBarInput}
        onKeyDown={this.handleKeyPress}
        onChange={this.updateSearchTerm}
      />
      // </div>
    );
  };
}

export default DishesIngSearchBar;
