// ErrorBoundary.js

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as actionCreators from "../actions/actionCreators";

import "../css/ErrorBoundary.css";

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   logErrorToMyService(error, errorInfo);
  // }

  render() {
    if (this.state.hasError) {
      const wrongText = this.props.main.dishName
        ? this.props.main.dishName
        : "the app";
      const homeLink = (
        <a href="https://soup.sifted.recipes" className="action">
          homepage
        </a>
      );
      const siftedLink = (
        <div id="header">
          <a href="https://soup.sifted.recipes" className="action">
            soup.sifted.recipes
          </a>
        </div>
      );
      // You can render any custom fallback UI
      return (
        <div className="errorBoundary">
          {siftedLink}
          <h1>Dang, something went wrong with {wrongText}.</h1>
          <h2>
            And error report has been logged. If refreshing the page doesn't
            work, try a new search on the {homeLink} while we fix this.
          </h2>
        </div>
      );
    }

    return this.props.children;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorBoundary);
