// DishesSearchResults.js

import React from "react";
import * as routerHelpers from "../helpers/routerHelpers";

import "../css/DishesSearchResults.css";

const DishesSearchResults = (props) => {
  const selectIngredient = (value) => {
    console.log(`selected: ${value}`);
    routerHelpers.addHighlightToURL(value, props.history);
    props.clearDishIngSearchResults();
  };

  const renderIng = (value, key, firstUnavailable) => {
    // if value is null, no results found for text input,
    // return no results message
    if (value.name === null) {
      return (
        <div key={key} className="dishSearchResult">
          no results found
        </div>
      );
    }
    let ingredientResultClasses = "dishSearchResult";
    let clickFunction = selectIngredient;
    let filteredOutMsg = null;
    if (firstUnavailable > -1 && key >= firstUnavailable) {
      ingredientResultClasses += " unavailable";
      clickFunction = () => {
        return null;
      };
    } else {
      ingredientResultClasses += " available";
    }
    if (key === firstUnavailable) {
      ingredientResultClasses += " firstUnavailable";
      filteredOutMsg = (
        <h4 id="filteredOutMsg">These ingredients have been filtered out:</h4>
      );
    }
    if (key === props.highlightedIndex - 1) {
      ingredientResultClasses += " dishHighlightedSuggestion";
    }
    return (
      <div
        className={ingredientResultClasses}
        key={key}
        onClick={clickFunction.bind(null, value.name)}
      >
        {filteredOutMsg}
        {value.name}
      </div>
    );
  };

  const renderIngredientResults = (results) => {
    const firstUnavailable = results.findIndex((e) => {
      return e.available === false;
    });
    return (
      <div>{results.map((e, i) => renderIng(e, i, firstUnavailable))}</div>
    );
  };

  // Callback function for the mouse click and touchstart
  // listeners. Closes the search results, removes event listeners.
  const clickOutOfResults = (event) => {
    if (
      !(
        event.target.className.includes("dishSearchResult") ||
        event.target.id === "dishSearchBarInput"
      )
    ) {
      // console.log("got to the closing part...");
      window.removeEventListener("mousedown", clickOutOfResults);
      window.removeEventListener("touchstart", clickOutOfResults);
      props.closeDishIngSearchResults();
    }
  };

  window.addEventListener("mousedown", clickOutOfResults);
  window.addEventListener("touchstart", clickOutOfResults);

  return (
    <div id="dishesSearchResultsContainer">
      <div id="dishesSearchResults" style={props.dishSearchWidth}>
        {props.dishIngredientResults &&
          renderIngredientResults(props.dishIngredientResults)}
      </div>
    </div>
  );
};

export default DishesSearchResults;
