// HighlightsAndFilters.js

import React from "react";

import "../css/HighlightsAndFiltersButtons.css";

const HighlightsAndFiltersButtons = ({
  clearDishIngSearchResults,
  toggleDishPageIngSearch,
  toggleFilters,
}) => {
  const handleClick = () => {
    clearDishIngSearchResults();
    toggleDishPageIngSearch();
    toggleFilters();
  };
  return (
    <div id="HaFbutton" onClick={handleClick}>
      <span className="material-icons">search</span> /
      <span className="material-icons">filter_alt</span>
    </div>
  );
};

export default HighlightsAndFiltersButtons;

// original layout with two separate buttons
// return (
// <div id="HaFbuttons">
// <div id="HaFsearchButton" onClick={handleSearchClick}>
//   <span className="material-icons">search</span>
// </div>
// <div id="HaFfilterButton" onClick={toggleFilters}>
//   <span className="material-icons">rule</span>
// </div>
// </div>
// );
