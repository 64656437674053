// HomeSearchBar.js
// css in Home.css

import React from "react";

import * as routerHelpers from "../helpers/routerHelpers";

class HomeSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  componentDidMount = () => {
    // Only auto-focus on text input when on a large screen.
    // This prevents on-screen keyboard from automatically 
    // popping up on mobile devices.
    if (window.innerWidth >= 769) {
      this.textInput.current.focus();
    }
    window.addEventListener("resize", this.updateWidthStyle);
    this.updateWidthStyle();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWidthStyle);
  };

  // get the width of the home page search bar so search
  // results div can be the same width
  updateWidthStyle = () => {
    const barRef = document.getElementById("homeSearchBarInput");
    const widthStyle = {
      width: barRef.clientWidth,
    };
    // console.log("widthStyle: ", widthStyle);
    this.props.updateHomeSearchWidth(widthStyle);
  };

  handleKeyPress = (event) => {
    // console.log("event.keyCode:");
    // console.log(event.keyCode);
    if (event.keyCode === 40) {
      // down arrow
      this.props.keyboardHighlightSearchSuggestion("DOWN");
    } else if (event.keyCode === 38) {
      // up arrow
      event.preventDefault();
      this.props.keyboardHighlightSearchSuggestion("UP");
    } else if (event.keyCode === 37) {
      // left arrow
      // only do stuff if a suggestion is actively highlighted
      if (this.props.main.highlightedSuggestionIndex > 0) {
        // only switch if on "INGREDIENTS" and going to "DISHES"
        event.preventDefault();
        if (this.props.main.selectedHomeSearchResults === "INGREDIENTS") {
          this.props.selectHomeSearchResults("DISHES");
          this.props.setHighlightedSuggestionIndex(1);
        }
      }
    } else if (event.keyCode === 39) {
      // right arrow
      // only do stuff if a suggestion is actively highlighted
      if (this.props.main.highlightedSuggestionIndex > 0) {
        // only switch if on "DISHES" and going to "INGREDIENTS"
        event.preventDefault();
        if (this.props.main.selectedHomeSearchResults === "DISHES") {
          this.props.selectHomeSearchResults("INGREDIENTS");
          this.props.setHighlightedSuggestionIndex(1);
        }
      }
    } else if (event.keyCode === 27) {
      // ESC key -> close the search results
      this.props.toggleSearchResults(false);
    } else if (event.keyCode === 13) {
      // enter
      event.preventDefault();
      if (this.props.main.highlightedSuggestionIndex > 0) {
        let url = "";
        const category = this.props.main.selectedHomeSearchResults;
        const index = this.props.main.highlightedSuggestionIndex - 1;
        let resultsText = "";
        if (category === "DISHES") {
          resultsText = this.props.main.searchResultsDishes[index];
          const linkName = resultsText.replace(/\s/g, "-");
          url = `/dishes/${linkName}`;
        } else {
          resultsText = this.props.main.searchResultsIngredients[index];
          const encodedIngName = encodeURIComponent(resultsText);
          const existingSearches = this.props.main.existingSearchTerms || [];
          const newSearches = existingSearches.concat([encodedIngName]);
          url = `/search?q=${newSearches.join("+")}`;
        }
        if (resultsText !== "_NOMATCHES") {
          this.props.history.push(url);
        }
      } else {
        const searchTermText = this.props.main.searchBarInput
          .trim()
          .toLowerCase();
        const parsedTerms = routerHelpers.parseSearchInput(searchTermText);
        let allIngsFound = true;
        parsedTerms.forEach((term) => {
          if (this.props.main.MIlookup[term] === undefined) {
            allIngsFound = false;
          }
        });
        const searchTermDish = this.props.main.searchBarInput
          .trim()
          .replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
        const dishesIncludes = this.props.main.searchResultsDishes.includes(
          searchTermDish
        );
        // console.log(
        //   `dishesIncludes: ${dishesIncludes}, ingredientsIncludes: ${allIngsFound}`
        // );
        if (!dishesIncludes && allIngsFound) {
          const existingSearches = this.props.main.existingSearchTerms || [];
          const encodedSearchTermText = encodeURIComponent(searchTermText);
          const newSearches = existingSearches.concat([encodedSearchTermText]);
          const urlIng = `/search?q=${newSearches.join("+")}`;
          this.props.history.push(urlIng);
        }
        if (dishesIncludes && !allIngsFound) {
          const withoutSpaces = searchTermDish.replace(/\s+/g, "-");
          const encodedWithoutSpaces = encodeURIComponent(withoutSpaces);
          const urlDish = `/dishes/${encodedWithoutSpaces}`;
          this.props.history.push(urlDish);
        }
      }
    }
  };

  updateSearchTerm = (event) => {
    const val = event.target.value;
    this.props.updateSearchBarInput(val);
    this.props.updateSearchResultsDishes();
    this.props.updateSearchResultsIngredients();
    if (val !== "") {
      this.props.toggleSearchResults(true);
    } else {
      this.props.toggleSearchResults(false);
    }
  };

  render = () => {
    let placeholder = "search by soup or ingredient";
    // console.log("this.props.location");
    // console.log(this.props.location);
    if (
      this.props.location.pathname === "/search" &&
      this.props.location.search.length > 0
    ) {
      placeholder = "add more ingredients";
    }
    // console.log("this.props.history.location.search");
    // console.log(this.props.history.location.search);
    return (
      <div id="homeSearchBar">
        <input
          id="homeSearchBarInput"
          type="text"
          ref={this.textInput}
          placeholder={placeholder}
          value={this.props.main.searchBarInput}
          onKeyDown={this.handleKeyPress}
          onChange={this.updateSearchTerm}
        />
      </div>
    );
  };
}

export default HomeSearchBar;
