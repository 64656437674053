// ExistingSearchTerms.js

import React from "react";

import "../css/ExistingSearchTerms.css";

const ExistingSearchTerms = (props) => {
  const clearAll = () => {
    props.history.push("");
  };

  const clearAllHTML =
    props.existingSearchTerms.length > 1 ? (
      <div className="existingSearchTerm" id="existingSearchTermClearAll" onClick={clearAll}>
        clear all
      </div>
    ) : null;

  const removeSearchTerm = (term) => {
    console.log(`removing search term: ${term}`);
    const newSearchTerms = props.existingSearchTerms.filter((value) => {
      return value !== term;
    });
    let newURL = `/`;
    if (newSearchTerms.length > 0) {
      newURL = `/search?q=${newSearchTerms.join("+")}`;
    }
    props.history.push(newURL);
  };

  const renderExistingSearchTerm = (term, key) => {
    return (
      <div className="existingSearchTerm" key={key}>
        <div>{term}</div>
        <div
          className="removeButton"
          onClick={removeSearchTerm.bind(null, term)}
        >
          <span className="material-icons">close</span>
        </div>
      </div>
    );
  };

  if (props.existingSearchTerms && props.existingSearchTerms.length > 0) {
    return (
      <div id="existingSearchTerms">
        <div id="existingSearchTermsHeader">
          <h3>Sifting for:</h3>
        </div>
        <div id="existingSearchTermsWrapper">
          {props.existingSearchTerms.map(renderExistingSearchTerm)}
          {clearAllHTML}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ExistingSearchTerms;
