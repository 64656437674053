// Nav.js

import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import firebase from 'firebase/app';
// import 'firebase/auth'

// import * as actionCreators from '../actions/actionCreators';
import ErrorBoundary from "./ErrorBoundary";
import Main from "./Main";
// import PNW from "./PNW";
// import Placeholder from "./Placeholder";

// function mapStateToProps(state) {
//   return state
// }

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(actionCreators, dispatch);
// }

// based on react-router docs:
// https://reacttraining.com/react-router/web/example/auth-workflow
class Nav extends React.Component {
  render = () => {
    return (
      <Router>
        <ErrorBoundary>
          <Route path="/" component={Main} />
        </ErrorBoundary>
      </Router>
    );
  };
}

// OLD - cleaned up switch statement 31MAR2022
// class Nav extends React.Component {
//   render = () => {
//     return (
//       <Router>
//         <ErrorBoundary>
//           <div>
//             {/* <Switch> */}
//               <Route path="/" component={Main} />
//               {/* <Route path="/pnw" component={PNW} /> */}
//               {/* <Route exact path="/" component={Placeholder} /> */}
//               {/* <Route component={Placeholder} /> */}
//             {/* </Switch> */}
//           </div>
//         </ErrorBoundary>
//       </Router>
//     );
//   };
// }

// const NoMatch = ({ location }) => (
//   <div>
//     <h3>
//       No match for <code>{location.pathname}</code>
//     </h3>
//   </div>
// );

// export default connect(mapStateToProps, mapDispatchToProps)(Nav);
export default Nav;
