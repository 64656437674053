// Home.js

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import * as actionCreators from "../actions/actionCreators";
import * as firebaseHelpers from "../helpers/firebaseHelpers";

import "../css/List.css";

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
}

class List extends React.Component {
  componentDidMount = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    if (!this.props.main.dishList) {
      const database = this.props.firebase.siftedproduction;
      firebaseHelpers.getDishList(this.props.dispatch, database);
    }
  };

  renderDishLink = (dishName, key) => {
    const linkName = dishName.replace(/\s/g, "-");
    const linkTo = `/dishes/${linkName}`;
    return (
      <div key={key} className="dishListItem">
        <Link to={linkTo} className="dishListLink">
          {dishName}
        </Link>
      </div>
    );
  };

  render = () => {
    const dishList = this.props.main.dishList;
    if (dishList) {
      return (
        <div className="workingDishList">
          <h1>List of Dishes</h1>
          <p>A-Z</p>
          {this.props.main.dishList.map(this.renderDishLink)}
        </div>
      );
    }
    return null;
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
