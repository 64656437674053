// Home.js

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as actionCreators from "../actions/actionCreators";
import * as firebaseHelpers from "../helpers/firebaseHelpers";
import * as routerHelpers from "../helpers/routerHelpers";

// import ByIngredient from "./ByIngredient";
import CommonlyPaired from "./CommonlyPaired";
import DishesThatInclude from "./DishesThatInclude";
import ExistingSearchTerms from "./ExistingSearchTerms";
import HomeSearchBar from "./HomeSearchBar";
import HomeSearchResults from "./HomeSearchResults";

import "../css/Home.css";
import firstLogo from "../images/firstLogo.svg";

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
}

class Home extends React.Component {
  componentDidMount = () => {
    // this.props.clearStore();
    const firebaseApp = this.props.firebase.siftedproduction;
    if (!this.props.main.dishList) {
      // const databaseSP = this.props.firebase.siftedproduction.firestore();
      firebaseHelpers.getDishList(this.props.dispatch, firebaseApp);
    }
    if (!this.props.main.MIlist) {
      // const databaseMI = this.props.firebase.masterIngredients.firestore();
      firebaseHelpers
        .getMasterIngredients(this.props.dispatch, firebaseApp)
        .then(() => {
          const urlPath = this.props.location.pathname;
          if (this.props.main.showSearchResults) {
            this.props.updateSearchResultsIngredients();
          } else if (urlPath === "/search") {
            const query = this.props.location.search.slice(3);
            const noEscapesQuery = query.replace(/%20/g, " ");
            if (noEscapesQuery.length && this.props.main.dishesWithIngredient) {
              const parsedQuery = routerHelpers.parseSearchInput(
                noEscapesQuery
              );
              firebaseHelpers.fetchCommonlyPaired(
                this.props.dispatch,
                firebaseApp,
                parsedQuery
              );
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  componentWillUnmount = () => {
    window.scrollTo(0, 0);
  };

  resetApp = () => {
    this.props.history.push("");
  };

  buildTagline = () => {
    if (
      !this.props.main.existingSearchTerms ||
      this.props.main.existingSearchTerms.length === 0
    ) {
      return (
        <div id="homeSplash">
          <div>
            <img id="firstLogo" src={firstLogo} alt="sifted recipes logo" />
          </div>
          <h3>Search differently. Cook differently.</h3>
          <div>
            We collect and organize recipe data from across the web, dish by
            dish.
            {/* Why? <span className="action">Learn more.</span> */}
          </div>
          <h4 id="warning">
            <span className="material-icons">campaign</span>
            We only have recipe data for soups published at this time.
          </h4>
        </div>
      );
    } else {
      return null;
    }
  };

  render = () => {
    const tagline = this.buildTagline();
    return (
      <div id="homePage">
        {tagline}
        <div id="homeSearchContainer">
          {this.props.main.existingSearchTerms && (
            <ExistingSearchTerms
              history={this.props.history}
              existingSearchTerms={this.props.main.existingSearchTerms}
            />
          )}
          <HomeSearchBar {...this.props} />
          {this.props.main.showSearchResults && (
            <HomeSearchResults
              dishes={this.props.main.searchResultsDishes}
              dispatch={this.props.dispatch}
              existingSearchTerms={this.props.main.existingSearchTerms}
              firebase={this.props.firebase}
              highlightedIndex={this.props.main.highlightedSuggestionIndex}
              history={this.props.history}
              homeSearchWidth={this.props.main.homeSearchWidth}
              ingredients={this.props.main.searchResultsIngredients}
              searchBarInput={this.props.main.searchBarInput}
              selectHomeSearchResults={this.props.selectHomeSearchResults}
              selectedHomeSearchResults={
                this.props.main.selectedHomeSearchResults
              }
              toggleSearchResults={this.props.toggleSearchResults}
            />
          )}
        </div>

        {this.props.main.commonlyPaired &&
          this.props.main.dishesWithIngredient &&
          this.props.main.dishesWithIngredient.length > 1 && (
            <CommonlyPaired
              commonlyPaired={this.props.main.commonlyPaired}
              commonlyPairedPage={this.props.main.commonlyPairedPage}
              existingSearchTerms={this.props.main.existingSearchTerms}
              history={this.props.history}
              lockout={this.props.main.lockoutCommonlyPaired}
              updateCommonlyPairedPage={this.props.updateCommonlyPairedPage}
            />
          )}
        {this.props.main.dishList &&
          this.props.main.dishList.length > 0 && (
            <DishesThatInclude {...this.props} />
          )}
      </div>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
