// ComponentTabs.js

import React from "react";

import "../css/ComponentTabs.css";

class ComponentTabs extends React.Component {
  getComps = (val) => {
    // console.log(`val: ${val}`);
    // if( parseInt(val, 10) === 98) {
    //   return {k: 98, n: "served with", o: 98}
    // }else {
      return this.props.main.components.find(comp => {
        return comp.k === parseInt(val, 10);
      });
    // }
  }

  renderTab = (component, key) => {
    let classes = "componentTab";
    if (component.k === this.props.main.selectedComponent.k) {
      classes += " highlightedComponent";
    }
    return (
      <div
        key={key}
        className={classes}
        onClick={this.props.selectComponent.bind(null, component)}
      >
        {component.n}
      </div>
    );
  };

  render = () => {
    const componentKeys = Object.keys(this.props.main.ingredientTree);
    const components = componentKeys.map(this.getComps); 
    components.sort((a,b) => a.o - b.o);
    return (
      <div className="componentTabs">{components.map(this.renderTab)}</div>
    );
  };
}

export default ComponentTabs;
