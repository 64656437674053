// FirebaseConfig.js

const firebaseConfigs = {
  siftedproduction: {
    apiKey: "AIzaSyB0aLEovrmW6PIruIWXwzgOwBR_xAGZrrU",
    authDomain: "siftedproduction.firebaseapp.com",
    databaseURL: "https://siftedproduction.firebaseio.com",
    projectId: "siftedproduction",
    storageBucket: "siftedproduction.appspot.com",
    messagingSenderId: "848915280651",
    appId: "1:848915280651:web:f0883048d30f3880d1e80e",
    measurementID: "G-EKEYN6PY0V",
  },
  recaptchaSiteKey: "6LezxeMeAAAAAApNmHEMOB24ww8sXm-lGNVARU6u",
};

export default firebaseConfigs;
