// Dish.js

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";

// import 'firebase/firestore';
import * as actionCreators from "../actions/actionCreators";
import * as firebaseHelpers from "../helpers/firebaseHelpers";
import * as routerHelpers from "../helpers/routerHelpers";

import ComponentTabs from "./ComponentTabs";
import DishesIngSearchBar from "./DishesIngSearchBar";
import DishesSearchResults from "./DishesSearchResults";
import FilterStatusBox from "./FilterStatusBox";
import FindARecipe from "./FindARecipe";
import IngredientTree from "./IngredientTree";
import HighlightsAndFiltersButtons from "./HighlightsAndFiltersButtons";

import "../css/Dish.css";

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
}

class Dish extends React.Component {
  componentDidMount = () => {
    const dishURL = this.props.match.params.dish;
    const dishName = dishURL.replace(/-/g, " ");
    // this.props.updateDebugWindow(`dishName: ${dishName}`);
    const firebaseApp = this.props.firebase.siftedproduction;
    this.registerFilters();
    firebaseHelpers.getDish(this.props.dispatch, firebaseApp, dishName);
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  };

  componentDidUpdate = (prevProps) => {
    // if (prevProps.location.pathname !== this.props.location.pathname) {
    //   console.log("trying to scroll to top")
    //   window.scrollTo(0, 0);
    // }
    this.checkForUpdatedSources(prevProps);
    if (prevProps.location.search !== this.props.location.search) {
      // console.log(`registered updated URL!`);
      this.checkForUpdatedFilters(prevProps);
      this.checkForUpdatedHighlights(prevProps);
    }
    // wait to register highlights until ingredient tree is loaded
    if (prevProps.main.ingList !== this.props.main.ingList) {
      this.registerHighlights();
    }
    const newHighlights = this.props.main.ingredientHighlights;
    if (
      newHighlights &&
      !this.arraysEqual(prevProps.main.ingredientHighlights, newHighlights)
    ) {
      if (newHighlights.length > 0) {
        const scrollTerm = newHighlights[0].replace(" ", "_");
        const el = document.getElementById(scrollTerm);
        this.scrollElementIntoView(el);
      }
    }
  };

  scrollElementIntoView = (element) => {
    if (element !== null) {
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      const elementTop = element.getBoundingClientRect().top;
      // only scroll down if element is not visible
      if (vh - 75 - elementTop < 0) {
        // const finalOffset = elementTop + scrollTop - vh / 2;
        const finalOffset = elementTop - vh / 2;
        window.parent.scrollTo({
          top: finalOffset,
          behavior: "smooth",
        });
      }
    }
  };

  checkForUpdatedFilters = (prevProps) => {
    const filterTerms = routerHelpers.parseFilters(this.props.location.search);
    const equalArrays = this.arraysEqual(
      prevProps.main.ingredientFilters,
      filterTerms
    );
    if (!equalArrays) {
      this.props.updateIngredientFilters(filterTerms);
      this.props.rebuildTree();
    }
  };

  checkForUpdatedHighlights = (prevProps) => {
    const highlightTerms = routerHelpers.parseHighlights(
      this.props.location.search
    );
    const equalArrays = this.arraysEqual(
      prevProps.main.ingredientHighlights,
      highlightTerms
    );
    if (!equalArrays) {
      this.props.updateIngredientHighlights(highlightTerms);
      window.addEventListener("mousedown", this.clickOutOfHighlights);
      window.addEventListener("touchstart", this.clickOutOfHighlights);
    }
  };

  // check if filtering by source websites, and if so,
  // update the ingredient tree.
  checkForUpdatedSources = (prevProps) => {
    // first make sure allSources is populated
    if (prevProps.main.allSources && this.props.main.allSources) {
      const oldSources = prevProps.main.allSources;
      const newSources = this.props.main.allSources;
      let different = false;
      for (const s in newSources) {
        if (newSources[s].show !== oldSources[s].show) {
          different = true;
          // console.log("Found a difference between sources.");
          break;
        }
      }
      if (different) {
        this.props.rebuildTree();
      }
    }
  };

  arraysEqual = (a, b) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  };

  registerFilters = () => {
    const filterTerms = routerHelpers.parseFilters(this.props.location.search);
    this.props.updateIngredientFilters(filterTerms);
  };

  registerHighlights = () => {
    const highlightTerms = routerHelpers.parseHighlights(
      this.props.location.search
    );
    this.props.updateIngredientHighlights(highlightTerms);
    if (highlightTerms.length > 0) {
      const scrollTerm = highlightTerms[0].replace(" ", "_");
      const el = document.getElementById(scrollTerm);
      this.scrollElementIntoView(el);
      window.addEventListener("mousedown", this.clickOutOfHighlights);
      window.addEventListener("touchstart", this.clickOutOfHighlights);
    }
  };

  // Callback function for the mouse click and touchstart
  // listeners. Removes all highlights.
  clickOutOfHighlights = () => {
    console.log("clicking out of highlights");
    window.removeEventListener("mousedown", this.clickOutOfHighlights);
    window.removeEventListener("touchstart", this.clickOutOfHighlights);
    routerHelpers.removeHighlightsFromURL(this.props.history);
  };

  scrollToRecipes = () => {
    document
      .querySelector("#Find-a-Recipe")
      .scrollIntoView({ behavior: "smooth" });
  };

  renderFindARecipe = (sticky = "") => {
    const recipes = this.props.main.recipes;
    const recipesToShow = this.props.main.recipesToShow;
    const numRecipes = recipes ? recipes.length : "";
    const numRecipesToShow = recipesToShow ? recipesToShow.length : "";
    if (numRecipesToShow !== numRecipes) {
      return (
        <div
          id="findARecipeTitle"
          className={sticky}
          onClick={this.scrollToRecipes}
        >
          <h2>
            Find a Recipe ({numRecipesToShow} of {numRecipes})
          </h2>
        </div>
      );
    } else {
      return (
        <div
          id="findARecipeTitle"
          className={sticky}
          onClick={this.scrollToRecipes}
        >
          <h2>Find a Recipe ({numRecipes})</h2>
        </div>
      );
    }
  };

  getNumRecipesDiv = () => {
    const recipes = this.props.main.recipes;
    const numRecipes = recipes ? recipes.length : 0;
    const workingRecipes = this.props.main.workingRecipes;
    const numWorkingRecipes = workingRecipes ? workingRecipes.length : 0;
    if (numRecipes === 0) {
      return <div id="numRecipes">We've sifted...</div>;
    }
    if (numWorkingRecipes === numRecipes) {
      return (
        <div id="numRecipes">
          We've sifted <strong>{numRecipes}</strong> recipes.
        </div>
      );
    } else {
      return (
        <div id="numRecipes">
          Filters applied, data for <strong>{numWorkingRecipes}{" "}
          of {numRecipes}</strong> recipes:
        </div>
      );
    }
  };

  render = () => {
    const printWorkingRecipes = this.getNumRecipesDiv();
    const filters = this.props.main.ingredientFilters || [];
    const components = this.props.main.components || [];
    // const debugText = this.props.main.debugWindow ? this.props.main.debugWindow : "no debug yet";
    return (
      <div id="dishPage">
        <div id="dishData">
          <div id="dishTitleArea">
            <div id="dishNameAndCount">
              <h1>{this.props.main.dishName}</h1>
              {printWorkingRecipes}
            </div>
            <HighlightsAndFiltersButtons
              clearDishIngSearchResults={this.props.clearDishIngSearchResults}
              toggleDishPageIngSearch={this.props.toggleDishPageIngSearch}
              toggleFilters={this.props.toggleFilters}
            />
          </div>
          <div id="dishSearchContainer">
            {this.props.main.showDishPageIngSearch && (
              <DishesIngSearchBar {...this.props} />
            )}
            {this.props.main.dishIngredientResults &&
              this.props.main.dishIngredientResults.length > 0 && (
                <DishesSearchResults
                  clearDishIngSearchResults={
                    this.props.clearDishIngSearchResults
                  }
                  closeDishIngSearchResults={
                    this.props.closeDishIngSearchResults
                  }
                  dishIngredientResults={this.props.main.dishIngredientResults}
                  dishSearchWidth={this.props.main.dishSearchWidth}
                  highlightedIndex={
                    this.props.main.highlightedSuggestionIndexDish
                  }
                  history={this.props.history}
                />
              )}
          </div>
          {filters.length > 0 && (
            <FilterStatusBox
              history={this.props.history}
              ingredientFilters={filters}
              ingredientHighlights={this.props.main.ingredientHighlights}
            />
          )}
          {components.length > 1 && <ComponentTabs {...this.props} />}
          {this.props.main.ingredientTree && <IngredientTree {...this.props} />}
        </div>
        <div id="Find-a-Recipe" className="sticky" />
        {this.props.main.ingredientTree && this.renderFindARecipe("sticky")}
        <div id="recipeSection">
          {this.props.main.ingredientTree && this.renderFindARecipe("flexy")}
          <div id="secondFilterStatusBox">
            {filters.length > 0 && (
              <FilterStatusBox
                history={this.props.history}
                ingredientFilters={filters}
                ingredientHighlights={this.props.main.ingredientHighlights}
              />
            )}
          </div>
          {this.props.main.recipesToShow && <FindARecipe {...this.props} />}
        </div>
      </div>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dish);
