// Contact.js

import React from "react";

import * as firebaseHelpers from "../helpers/firebaseHelpers.js";

import "../css/Contact.css";

const Contact = (props) => {
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const goodEmail = validateEmail(props.main.contactEmail);
    if (!goodEmail) {
      props.updateConfirmMsg("ERROR_EMAIL");
    }
    const goodMessage = props.main.contactMessage.length > 0;
    if (!goodMessage) {
      props.updateConfirmMsg("ERROR_MSG");
    }
    if (goodEmail && goodMessage) {
      firebaseHelpers.submitContactForm(
        props.dispatch,
        props.firebase.siftedproduction,
        props.main.contactEmail,
        props.main.contactMessage
      );
    }
  };

  const parseMsgConfirm = (msg) => {
    switch (msg) {
      case "SUCCESS":
        return (
          <div id="submitConfirmSuccess">
            Your message has been sent! Thank you for your feedback.
          </div>
        );
      case "ERROR":
        return (
          <div id="submitConfirmError">
            Your message has been sent! Thank you for your feedback.
          </div>
        );
      case "ERROR_EMAIL":
        return <div id="submitConfirmError">Please provide a valid email.</div>;
      case "ERROR_MSG":
        return <div id="submitConfirmError">Message must not be empty.</div>;
      default:
        return null;
    }
  };

  const confirmation = parseMsgConfirm(props.main.contactMsgConfirm);

  return (
    <div id="contactWrapper">
      <div id="contactContent">
        <h1>Contact us!</h1>
        <div id="contactHeader">
          Do you have questions or suggestions? We'd love to hear from you.
        </div>
        <form id="contactForm" onSubmit={handleSubmit}>
          <div>
            <label>Your email:</label>
            <input
              id="contactEmailInput"
              type="text"
              value={
                props.main.contactEmail // disabled={true}
              }
              onChange={props.updateContactEmail}
            />
          </div>
          <div>
            <label>Your message:</label>
            <textarea
              id="contactMessageTextarea"
              value={props.main.contactMessage}
              onChange={props.updateContactMessage}
            />
          </div>

          {confirmation}
          <input id="contactSubmit" type="submit" value="Send Message" />
        </form>
      </div>
    </div>
  );
};

export default Contact;
