// Main.js

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

import Contact from "./Contact";
import Dish from "./Dish";
import Header from "./Header";
import Home from "./Home";
import Footer from "./Footer";
import List from "./List";
import NotFound from "./NotFound";
import WhatIsSifted from "./WhatIsSifted";
import * as actionCreators from "../actions/actionCreators";
import * as firebaseHelpers from "../helpers/firebaseHelpers";
import * as routerHelpers from "../helpers/routerHelpers";

import "../css/Main.css";

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
}

class Main extends React.Component {
  componentDidMount = () => {
    const prodApp = firebaseHelpers.initializeFirebase(
      this.props.dispatch,
      "siftedproduction"
    );
    // const analytics = prodDB.analytics();

    this.reconcileURL(prodApp);
  };

  componentDidUpdate = (prevProps) => {
    const oldPath = prevProps.location.pathname;
    const newPath = this.props.location.pathname;
    const oldSearch = prevProps.location.search;
    const newSearch = this.props.location.search;
    if (newPath !== oldPath || newSearch !== oldSearch) {
      // console.log('reconcile!');
      this.reconcileURL();
    }
  };

  reconcileURL = (firebaseApp = this.props.firebase.siftedproduction) => {
    const rawDishesWithResults = this.props.main.rawDishesWithResults || {};
    // const existingSearchTerms = this.props.main.existingSearchTerms || [];
    routerHelpers.URLtoState(
      this.props.location,
      this.props.dispatch,
      firebaseApp,
      rawDishesWithResults
      // existingSearchTerms
    );
  };

  render = () => {
    const firebaseConnected = this.props.firebase.siftedproduction;
    if (firebaseConnected) {
      return (
        <div id="main-wrapper">
          <div>
            <Header
              clearStore={this.props.clearStore}
              existingSearchTerms={this.props.main.existingSearchTerms}
              history={this.props.history}
              showNavMenu={this.props.main.showNavMenu}
              toggleNavMenu={this.props.toggleNavMenu}
            />
            {/* <div className="home"> */}
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/search" component={Home} />
              <Route exact path="/dishes/" component={List} />
              <Route path="/dishes/:dish" component={Dish} />
              <Route path="/what-is-sifted">
                <WhatIsSifted />
              </Route>
              <Route path="/contact">
                <Contact {...this.props} />
              </Route>
              <Route path="/*">
                <NotFound />
              </Route>
            </Switch>
          </div>
          <Footer clearStore={this.props.clearStore} />
        </div>
      );
    } else {
      return null;
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
