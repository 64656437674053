// store.js

import { createLogger } from "redux-logger";
import { createStore, applyMiddleware } from "redux";

import rootReducer from "./reducers/index";

const loggerMiddleware = createLogger();

// create an object for the default data

const defaultState = {
  main: {
    contactEmail: "",
    contactMessage: "",
    dishSearchBarInput: "",
    searchBarInput: "",
    selectedHomeSearchResults: "DISHES",
    showNavMenu: false,
  },
};

let store = createStore(rootReducer, defaultState);

// overwrite and add loggerMiddleware to development build.
if (process.env.NODE_ENV === "development") {
  store = createStore(
    rootReducer,
    defaultState,
    applyMiddleware(loggerMiddleware)
  );
}

export default store;
