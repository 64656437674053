// routerHelpers.js
// For helping sync router state with redux state.

import * as actions from "../actions/actionCreators";
import * as firebaseHelpers from "../helpers/firebaseHelpers";

// Updates the filter params on a dish page URL.
// location search params could already be messy with highlights and filters:
// ?highlight=beans+chicken%20stock&filter=lemon%20zest
// Highlights always come before filters in URL scheme.
export const updateFiltersInURL = (val, history, oldFilters, highlights) => {
  console.log(`updating filter term: ${val}`);
  const pathname = history.location.pathname;
  const newFilters = [].concat(oldFilters);
  console.log(`existing filters: `, oldFilters);
  // check if val already exists in oldFilters.
  const existingIndex = newFilters.indexOf(val);
  const oppositeIndex = newFilters.indexOf(val.replace("no_", "yes_"));
  if (existingIndex !== -1) {
    // remove completely if already exists
    newFilters.splice(existingIndex, 1);
  } else if (oppositeIndex !== -1) {
    // remove and replace if opposite exists
    newFilters.splice(oppositeIndex, 1, val);
  } else {
    // add new to end of array
    newFilters.push(val);
  }
  let highlightText = "";
  if (highlights.length > 0) {
    // build URL text chunk with encoded highlight
    const highlightsEncoded = highlights.map((h) => encodeURIComponent(h));
    highlightText = `highlight=${highlightsEncoded.join("+")}`;
  }
  let filterText = "";
  if (newFilters.length > 0) {
    // build URL text chunk with encoded filters
    const filtersEncoded = newFilters.map((f) => encodeURIComponent(f));
    filterText = `filter=${filtersEncoded.join("+")}`;
  }
  let newURL = pathname;
  if (highlightText.length > 0) {
    // if highlights, make sure to add them first
    newURL = newURL + "?" + highlightText;
    if (filterText.length > 0) {
      // add filters if they exist
      newURL = newURL + "&" + filterText;
    }
  } else {
    // no highlights
    if (filterText.length > 0) {
      // add just filters
      newURL = newURL + "?" + filterText;
    }
  }
  history.replace(newURL);
};

// Get rid of all filters in the URL.
export const clearFiltersInURL = (history, highlights) => {
  console.log("clearFiltersInURL");
  console.log(history);
  console.log(highlights);
  let newURL = history.location.pathname;
  if (highlights.length > 0) {
    // build URL text chunk with encoded highlights
    const highlightsEncoded = highlights.map((h) => encodeURIComponent(h));
    const highlightText = `highlight=${highlightsEncoded.join("+")}`;
    newURL = newURL + "?" + highlightText;
  }
  history.replace(newURL);
};

// Add a new highlight term to the URL.  Overwrite
// any existing highlight terms.  Highlights always
// come before filters in URL scheme.
export const addHighlightToURL = (val, history) => {
  // console.log(`adding highlight term: ${val}`);
  const encodedVal = encodeURIComponent(val);
  const pathname = history.location.pathname;
  const oldSearch = history.location.search;
  // console.log(`existing search: ${history.location.search}`);
  const filterIndex = oldSearch.indexOf(`filter=`);
  let filterChunk = ``;
  if (filterIndex !== -1) {
    filterChunk = `&` + oldSearch.slice(filterIndex);
  }
  const highlightChunk = `highlight=${encodedVal}`;
  const newURL = pathname + `?` + highlightChunk + filterChunk;
  history.replace(newURL);
};

// Remove all highlights from the URL.
export const removeHighlightsFromURL = (history) => {
  const pathname = history.location.pathname;
  const oldSearch = history.location.search;
  // console.log(`existing search: ${history.location.search}`);
  const filterIndex = oldSearch.indexOf(`filter=`);
  let filterChunk = ``;
  if (filterIndex !== -1) {
    filterChunk = `?` + oldSearch.slice(filterIndex);
  }
  const newURL = pathname + filterChunk;
  history.replace(newURL);
}

export const removeFilterFromURL = (val, history) => {
  console.log(`removing filter term: ${val}`);
  const pathname = history.location.pathname;
  const search = history.location.search;
  let searchStart = "";
  let termString = "";
  const searchTerms = decodeURIComponent(search.slice(8));
  const splitTerms = searchTerms.split(`+`);
  const remainingTerms = splitTerms.filter((term) => term !== val);
  if (remainingTerms.length > 0) {
    searchStart = `?filter=`;
    termString = remainingTerms.map((val) => encodeURIComponent(val)).join(`+`);
  }
  const newURL = pathname + searchStart + termString;
  history.replace(newURL);
};

// look at the search text in the url and return
// an array with the appropriate filters.
export const parseFilters = (text) => {
  let filterArray = [];
  const filterIndex = text.indexOf(`filter=`);
  let highlightIndex = text.indexOf(`highlight=`);
  if (highlightIndex === -1 || highlightIndex < filterIndex) {
    highlightIndex = undefined;
  }
  if (filterIndex !== -1) {
    const filterTerms = text.slice(filterIndex + 7, highlightIndex);
    filterArray = filterTerms
      .split(`+`)
      .map((v) => v.replace(`&`, ``))
      .map((v) => decodeURIComponent(v));
  }
  return filterArray;
};

// looks at the search text in the url and returns
// an array with the highlighted terms
export const parseHighlights = (text) => {
  let highlightArray = [];
  const highlightIndex = text.indexOf(`highlight=`);
  let filterIndex = text.indexOf(`filter=`);
  if (filterIndex === -1 || filterIndex < highlightIndex) {
    filterIndex = undefined;
  }
  if (highlightIndex !== -1) {
    const highlightTerms = text.slice(highlightIndex + 10, filterIndex);
    highlightArray = highlightTerms
      .split(`+`)
      .map((v) => v.replace("&", ""))
      .map((v) => decodeURIComponent(v));
  }
  return highlightArray;
};

export const parseSearchInput = (text) => {
  const searchTerms = text.split("+");
  searchTerms.forEach((term, i) => {
    searchTerms[i] = decodeURIComponent(term.trim());
  });
  // console.log(`searchTerms: `, searchTerms);
  return searchTerms;
};

// Parses the URL when on the home page search and dispatches
// the appropriate actions.
export const URLtoState = (location, dispatch, firebaseApp, rawDishesWithResults) => {
  if (location.pathname === "/search") {
    // remove '?q=' from value
    const searchText = location.search.slice(3);
    // remove any '%20' whitespace
    const noEscapesQuery = searchText.replace(/%20/g, " ");
    // console.log("noEscapesQuery:", noEscapesQuery);
    // dispatch(actions.updateSearchBarInput(noEscapesQuery));
    if (noEscapesQuery.length > 0) {
      dispatch(actions.startLockout());
      const searchTerms = parseSearchInput(noEscapesQuery);
      dispatch(actions.updateExistingSearchTerms(searchTerms));
      dispatch(actions.toggleSearchResults(false));
      firebaseHelpers.fetchCommonlyPaired(dispatch, firebaseApp, searchTerms);
      firebaseHelpers.searchDishesByIngredient(
        dispatch,
        firebaseApp,
        searchTerms,
        rawDishesWithResults
      );
    }
  } else if (location.pathname === "" || location.pathname === "/") {
    dispatch(actions.toggleSearchResults(false));
    dispatch(actions.clearStore());
  }
};
