// Header.js

import React from "react";
import { Link } from "react-router-dom";

import "../css/Header.css";
import logo from "../images/logo.svg";

const BurgerMenu = ({ clearStore, pathname, showNavMenu, toggleNavMenu }) => {
  let showHomeLink = true;
  if (pathname === "/" || pathname === "/search") {
    showHomeLink = false;
  }
  let navDropdown = null;
  if (showNavMenu) {
    navDropdown = (
      <div id="navDropdown">
        {showHomeLink && (
          <Link to="/" className="action dropdownLink" onClick={clearStore}>
            Home
          </Link>
        )}
        <Link to="/dishes" className="action dropdownLink" onClick={clearStore}>
          Soups A-Z
        </Link>
        <Link
          to="/what-is-sifted"
          className="action dropdownLink"
          onClick={clearStore}
        >
          What is Sifted?
        </Link>
        <Link
          to="/contact"
          className="action dropdownLink"
          onClick={clearStore}
        >
          Contact
        </Link>
      </div>
    );

    // Callback function for the mouse click and touchstart
    // listeners. Closes the navDropdown, removes event listeners.
    const clickOutOfNavDropdown = (event) => {
      console.log("clickOUtOfNavDropdown fired...");
      console.log("target:", event);
      window.removeEventListener("mousedown", clickOutOfNavDropdown);
      window.removeEventListener("touchstart", clickOutOfNavDropdown);
      if (!event.target.className.includes("dropdownLink")) {
        if (
          !(
            event.target.id === "burgerIcon" ||
            event.target.parentElement.id === "burgerIcon"
          )
        ) {
          toggleNavMenu();
        }
      }
    };

    // add event listeners when dropdown is shown so a click anywhere
    // on the page toggles the dropdown closed again.
    window.addEventListener("mousedown", clickOutOfNavDropdown);
    window.addEventListener("touchstart", clickOutOfNavDropdown);
  }

  return (
    <div id="burgerWrapper">
      <div id="burgerIcon" onClick={toggleNavMenu}>
        <span className="material-icons">menu</span>
      </div>
      {navDropdown}
    </div>
  );
};

const Header = ({
  clearStore,
  existingSearchTerms,
  history,
  showNavMenu,
  toggleNavMenu,
}) => {
  // populate if not on homepage, or if on homepage and search
  // is active.
  let fullHeader = (
    <div id="header">
      <Link to="/" onClick={clearStore}>
        <div>
          <img id="headerLogoSVG" src={logo} alt="sifted logo" />
        </div>
      </Link>
      <div id="menuLinks">
        <Link to="/dishes" className="action" onClick={clearStore}>
          Soups A-Z
        </Link>
        <Link to="/what-is-sifted" className="action" onClick={clearStore}>
          What is Sifted?
        </Link>
      </div>
    </div>
  );

  // do not include the full header if on the homepage
  // and no search has been initiated.
  if (
    history.location.pathname === "/" &&
    (!existingSearchTerms || existingSearchTerms.length === 0)
  ) {
    fullHeader = null;
  }

  return (
    <div>
      {fullHeader}
      <BurgerMenu
        clearStore={clearStore}
        pathname={history.location.pathname}
        showNavMenu={showNavMenu}
        toggleNavMenu={toggleNavMenu}
      />
    </div>
  );
};

export default Header;
