import React from "react";

import RecipeLinks from "./RecipeLinks";
import RecipeSources from "./RecipeSources";

import "../css/FindARecipe.css";

const FindARecipe = (props) => {
  // Only show the reset button if sources are showing and
  // not all sources are selected.
  let resetButton = null;
  let showReset = false;
  for (const ws of props.main.workingSources) {
    if (ws.lockout === false && props.main.allSources[ws.name].show === false) {
      showReset = true;
      break;
    }
  }
  if (showReset) {
    resetButton = (
      <div id="sourcesResetButton" onClick={props.resetSources}>
        reset all
      </div>
    );
  }

  const expandCarrot = props.main.showFilterBySource ? (
    <span className="material-icons">expand_less</span>
  ) : (
    <span className="material-icons">expand_more</span>
  );

  return (
    <div id="recipes">
      <div id="recipesHeader">
        <div id="filterBySourceHeader">
          <div id="filterBySourceButton" onClick={props.toggleFilterBySource}>
            {expandCarrot}
            filter by website
          </div>
          {resetButton}
        </div>

        {props.main.workingSources &&
          props.main.showFilterBySource && (
            <RecipeSources
              allSources={props.main.allSources}
              workingSources={props.main.workingSources}
              toggleOnlySource={props.toggleOnlySource}
              toggleSource={props.toggleSource}
            />
          )}
      </div>
      {props.main.recipesToShow && (
        <RecipeLinks recipes={props.main.recipesToShow} />
      )}
    </div>
  );
};

export default FindARecipe;
