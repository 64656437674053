// IngredientTree.js

import React from "react";

import IngredientBranch from "./IngredientBranch";
import "../css/IngredientTree.css";

class IngredientTree extends React.Component {
  formatPercentage = (decimal) => {
    const hundo = decimal * 100;
    if (hundo >= 1) {
      return hundo.toFixed(1);
    } else {
      return hundo.toFixed(2);
    }
  };

  renderBranch = (value, key, compKey, offset = 0, highlightOverride) => {
    const branchKey = Object.keys(value)[0];
    const name = value[branchKey].name;
    const percentage = this.formatPercentage(value[branchKey].percentage);
    let children = null;
    if (value[branchKey].children) {
      let newOverride = highlightOverride;
      if (
        newOverride ||
        this.props.main.ingredientHighlightsApplied.includes(name)
      ) {
        // this ingredient will be highlighted, don't highlight children
        newOverride = true;
      }
      children = value[branchKey].children.map((value, key) => {
        return this.renderBranch(value, key, compKey, offset + 1, newOverride);
      });
    }
    const showChildren = this.props.main.showChildren
      ? this.props.main.showChildren[compKey]
      : null;
    return (
      <IngredientBranch
        children={children}
        compKey={compKey}
        filtersShow={this.props.main.filtersShow}
        first={value[branchKey].first}
        generation={offset}
        history={this.props.history}
        highlightOverride={highlightOverride}
        ingredientFilters={this.props.main.ingredientFilters}
        ingredientHighlights={this.props.main.ingredientHighlights}
        ingredientHighlightsApplied={
          this.props.main.ingredientHighlightsApplied
        }
        key={key}
        name={name}
        percentage={percentage}
        rebuildTree={this.props.rebuildTree}
        showChildren={showChildren}
        showIngredientInfo={this.props.main.showIngredientInfo}
        toggleChildren={this.props.toggleChildren}
        toggleIngredientInfo={this.props.toggleIngredientInfo}
        updateIngredientFilters={this.props.updateIngredientFilters}
      />
    );
  };

  render = () => {
    const compKey = this.props.main.selectedComponent.k;
    let componentToRender = this.props.main.ingredientTree
      ? this.props.main.ingredientTree[compKey]
      : null;
    const ingInfoBox = this.props.main.showIngredientInfo ? (
      <div id="ingInfoBox">% of recipes that include the ingredient</div>
    ) : null;
    return (
      <div id="ingredientTree">
        {componentToRender &&
          componentToRender.map((value, key) => {
            return this.renderBranch(value, key, compKey, 0);
          })}
        {ingInfoBox}
      </div>
    );
  };
}

export default IngredientTree;
