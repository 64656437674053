// index.js

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './store';
import Nav from './components/Nav';
import * as serviceWorker from './serviceWorker';
import './css/index.css';

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const main = (
	<Provider store={store}>
      <Nav />
	</Provider>
)

ReactDOM.render(main, document.getElementById('root'));
// registerServiceWorker();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();