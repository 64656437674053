// Footer.js
import React from "react";
import { Link } from "react-router-dom";

import "../css/Footer.css";

const Footer = ({ clearStore }) => {
  return (
    <div id="footer">
      <div id="footerLinks">
        <div>
          <Link to="/dishes" className="action" onClick={clearStore}>
            {" "}
            Soups A-Z
          </Link>
        </div>
        <div>
          <Link to="/what-is-sifted" className="action" onClick={clearStore}>
            {" "}
            What is Sifted?
          </Link>
        </div>
        <div>
          <Link to="/contact" className="action" onClick={clearStore}>
            {" "}
            Contact
          </Link>
        </div>
      </div>
      <div id="footerCopyright">copyright Sifted Recipes LLC 2021</div>
    </div>
  );
};

export default Footer;
