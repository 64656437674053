// main.js
// main reducer

import Fuse from "fuse.js";

import * as treeHelpers from "../helpers/treeHelpers";

import {
  // BUILD_RECIPE_LIST,
  CLEAR_DISH_ING_SEARCH_RESULTS,
  CLEAR_DW_AND_CP,
  CLEAR_SEARCH,
  CLEAR_STORE,
  CLOSE_ALL_PARENTS,
  CLOSE_DISH_ING_SEARCH_RESULTS,
  FIND_COMMONLY_PAIRED,
  KEYBOARD_HIGHLIGHT_SEARCH_SUGGESTION,
  KEYBOARD_HIGHLIGHT_SEARCH_SUGGESTION_DISH,
  KEYBOARD_SELECT_SEARCH_SUGGESTION,
  OPEN_ALL_PARENTS,
  REBUILD_TREE,
  RESET_SOURCES,
  SELECT_COMPONENT,
  SELECT_HOME_SEARCH_RESULTS,
  SET_HIGHLIGHTED_SUGGESTION_INDEX,
  START_LOCKOUT,
  TOGGLE_CHILDREN,
  TOGGLE_DISH_PAGE_ING_SEARCH,
  TOGGLE_FILTERS,
  TOGGLE_FILTER_BY_SOURCE,
  TOGGLE_INGREDIENT_INFO,
  TOGGLE_NAV_MENU,
  TOGGLE_ONLY_SOURCE,
  TOGGLE_SEARCH_RESULTS,
  // TOGGLE_SHOW_MORE,
  TOGGLE_SOURCE,
  TOGGLE_DISH_OPTIONS_INFO,
  UPDATE_COMMONLY_PAIRED_PAGE,
  UPDATE_COMMONLY_PAIRED_PRIMARY,
  UPDATE_COMMONLY_PAIRED_SECONDARY,
  UPDATE_CONFIRM_MSG,
  UPDATE_CONTACT_EMAIL,
  UPDATE_CONTACT_MESSAGE,
  UPDATE_DEBUG_WINDOW,
  // UPDATE_DISH,
  UPDATE_DISH_COMPRESSED,
  UPDATE_DISH_INGREDIENT_SEARCH,
  UPDATE_DISH_LIST,
  UPDATE_DISH_SEARCH_BAR_INPUT,
  UPDATE_DISH_SEARCH_WIDTH,
  UPDATE_DISHES_WITH_INGREDIENT,
  UPDATE_EXISTING_SEARCH_TERMS,
  UPDATE_HOME_SEARCH_WIDTH,
  UPDATE_INGREDIENT_FILTERS,
  UPDATE_INGREDIENT_HIGHLIGHTS,
  UPDATE_INGREDIENT_SEARCH_DISHES,
  UPDATE_MASTER_INGREDIENTS,
  UPDATE_MINI_MI,
  UPDATE_SEARCH_BAR_INPUT,
  UPDATE_SEARCH_RESULTS_DISHES,
  UPDATE_SEARCH_RESULTS_INGREDIENTS,
} from "../actions/actionCreators";

// Build a list of sources and recipe counts
// from ALL recipes for the dish.
function buildAllSources(recipes) {
  const results = {};
  recipes.forEach((r) => {
    if (results[r.source] === undefined) {
      results[r.source] = {
        count: 1,
        // lockout: false,
        only: false,
        show: true,
      };
    } else {
      results[r.source].count += 1;
    }
  });
  return results;
}

// takes an array of highlight terms, finds all matches and
// children matches in the MIlist, and returns an array of
// ingredient names to highlight.
function buildHighlightsFromMI(highlights, MIlist, MIlookup) {
  // const startTime = Date.now();
  if (!highlights) {
    return [];
  }
  let resultKeys = [];
  let currentSearchKeys = [];
  let newMatchKeys = [];
  highlights.forEach((h) => {
    currentSearchKeys.push(MIlookup[h]);
  });
  while (currentSearchKeys.length > 0) {
    for (const key in MIlist) {
      let ingParentKey = Object.keys(MIlist[key].parent)[0];
      if (currentSearchKeys.includes(ingParentKey)) {
        newMatchKeys.push(key);
      }
    }
    resultKeys = resultKeys.concat(currentSearchKeys);
    currentSearchKeys = [].concat(newMatchKeys);
    newMatchKeys = [];
  }
  const result = resultKeys.map((k) => MIlist[k].ingredient_name);
  // console.log(
  //   `buildHighlightsFromMI finished in: ${Date.now() - startTime}ms.`
  // );
  return result;
}

// takes an array of highlight terms, finds all matches and
// children matches in the ingList, and returns an array of
// ingredient names to highlight.
function buildHighlightsFromIngList(highlights, ingList, parents) {
  const results = ingList.filter((ing) => {
    return highlights.includes(ing);
  });
  let addedNew = true;
  while (addedNew) {
    addedNew = false;
    for (const child in parents[0]) {
      const p = parents[0][child];
      if (results.includes(p)) {
        if (!results.includes(child)) {
          results.push(child);
          addedNew = true;
        }
      }
    }
  }
  return results;
}

function buildMIarray(MIlist) {
  const MIarray = [];
  for (const key in MIlist) {
    MIarray.push(MIlist[key]);
  }
  return MIarray;
}

function buildMIlookupObj(MIlist) {
  const lookupObj = {};
  for (const key in MIlist) {
    const name = MIlist[key].ingredient_name;
    lookupObj[name] = key;
  }
  return lookupObj;
}

// build an array of master ingredients that doesn't
// include ingredients with the "noSearch" tag.
function buildMIsearch(MIlist) {
  const results = [];
  for (const key in MIlist) {
    if (!MIlist[key].noSearch) {
      results.push(MIlist[key]);
    }
  }
  return results;
}

// update the list of sources when "only"
// is clicked for a recipe source.
function buildOnlySources(onlyName, allSources, history) {
  // console.log("onlyName: ", onlyName);
  // console.log("allSources: ", allSources);
  // console.log("history: ", history);
  let newHistory = history ? Object.assign({}, history) : undefined;
  let currentSources = {};
  if (allSources[onlyName].only) {
    // reset to previous history
    currentSources = history;
    newHistory = undefined;
  } else {
    // add old sources to history and build new sources
    // only add previous sources if history does not exist
    if (history === undefined) {
      newHistory = allSources;
    }
    for (const name in allSources) {
      if (name === onlyName) {
        currentSources[name] = {
          count: allSources[name].count,
          only: true,
          show: true,
        };
      } else {
        currentSources[name] = {
          count: allSources[name].count,
          only: false,
          show: false,
        };
      }
    }
  }
  // console.log("newHistory:");
  // console.log(newHistory);
  return { history: newHistory, sources: currentSources };
}

// Take the recipe rating string and reformat it.
// BEWARE HACK THAT IGNORES RATINGS THAT DON'T HAVE NUMBER
// OF REVIEWS!  
function buildRatingToDisplay(rating) {
  let prettyRating = "";
  if (rating.length > 0) {
    const splitRating = rating.split(" ");
    // double check that number of reviews is not zero
    const re = /\(|\)/g;
    let numReviews = 0;
    if(splitRating.length > 2) {
      numReviews = splitRating[2].split(re)[1];
    }
    if (!isNaN(numReviews) && numReviews > 0) {
      const textRating = splitRating[0].split("/");
      let displayRatingNumerator = textRating[0];
      if (displayRatingNumerator.includes(".")) {
        displayRatingNumerator = Number(textRating[0]).toFixed(1);
      }
      const displayFraction = `${displayRatingNumerator}/${textRating[1]}`;
      const displayEnd = `${splitRating[2].replace(")", " reviews)")}`;
      prettyRating = `${displayFraction} ${splitRating[1]} ${displayEnd}`;
    }
  }
  return prettyRating;
}

function buildRecipeSources(recipes, allSources) {
  const sources = [];
  const tempResults = {};
  recipes.forEach((recipe) => {
    if (tempResults[recipe.source] === undefined) {
      tempResults[recipe.source] = 1;
    } else {
      tempResults[recipe.source] += 1;
    }
  });
  for (const sourceName in allSources) {
    const count = tempResults[sourceName] ? tempResults[sourceName] : 0;
    const lockout = count > 0 ? false : true;
    sources.push({
      name: sourceName,
      count: count,
      lockout: lockout,
    });
  }
  sources.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
  return sources;
}

function buildRecipesToShow(recipes, allSources) {
  const results = recipes.filter((r) => {
    return allSources[r.source].show;
  });
  results.sort((a, b) => {
    const pointDiff = b.sortPoints - a.sortPoints;
    if (pointDiff !== 0) {
      return pointDiff;
    } else {
      return a.title.localeCompare(b.title);
    }
  });
  return results;
}

function buildShowChildren(name, compKey, oldShowChildren) {
  if (oldShowChildren === undefined) {
    return {
      [compKey]: {
        [name]: true,
      },
    };
  } else {
    if (!oldShowChildren[compKey]) {
      return Object.assign({}, oldShowChildren, {
        [compKey]: {
          [name]: true,
        },
      });
    } else {
      let newComp = null;
      // const newVal = oldShowChildren[compKey][name] ? false : true;
      newComp = Object.assign({}, oldShowChildren[compKey], {
        [name]: true,
      });
      // console.log("newComp:");
      // console.log(newComp);
      return Object.assign({}, oldShowChildren, {
        [compKey]: newComp,
      });
    }
  }
}

function buildShowChildren2(filters, highlights, parents, showChildren) {
  let results = Object.assign({}, showChildren);
  // console.log(`buildShowChildren2:`);
  // console.log(`filters: `, filters);
  // console.log(`highlights: `, highlights);
  // console.log(`parents: `, parents);
  // console.log(`showChildren: `, showChildren);
  const yesFiltersTemp = filters.filter((f) => f.includes("yes_"));
  const yesFilters = yesFiltersTemp.map((f) => f.slice(4));
  // console.log(`yesFilters: `, yesFilters);
  const showIngs = [].concat(yesFilters, highlights);
  // console.log(`showIngs: `, showIngs);
  showIngs.forEach((i) => {
    const ancestors = [];
    for (const comp in parents) {
      let moreParents = true;
      let currentName = i;
      while (moreParents) {
        const tempParent = parents[comp][currentName];
        if (tempParent !== undefined) {
          ancestors.push(tempParent);
          currentName = tempParent;
        } else {
          moreParents = false;
        }
      }
    }
    ancestors.forEach((name) => {
      for (const comp in parents) {
        results = buildShowChildren(name, comp, results);
      }
    });
  });
  // console.log("results: ", results);
  return results;
}

function buildShowChildrenForHighlights(name, compKey, oldShowChildren) {
  if (!oldShowChildren[compKey]) {
    return Object.assign({}, oldShowChildren, {
      [compKey]: {
        [name]: true,
      },
    });
  } else {
    const newComp = Object.assign({}, oldShowChildren[compKey], {
      [name]: true,
    });
    // console.log("newComp:");
    // console.log(newComp);
    return Object.assign({}, oldShowChildren, {
      [compKey]: newComp,
    });
  }
}

// use the ingredient filters to automatically
// expand the appropriate parents in the tree.
function buildShowChildrenFromFilters(filters, parents, oldShowChildren) {
  let results = Object.assign({}, oldShowChildren);
  filters.forEach((val) => {
    if (val.includes("yes_")) {
      const name = val.slice(4);
      const ancestors = [];
      for (const comp in parents) {
        let moreParents = true;
        let currentName = name;
        while (moreParents) {
          const tempParent = parents[comp][currentName];
          if (tempParent !== undefined) {
            ancestors.push(tempParent);
            currentName = tempParent;
          } else {
            moreParents = false;
          }
        }
      }
      // console.log(`ancestors:`);
      // console.log(ancestors);
      ancestors.forEach((name) => {
        for (const comp in parents) {
          results = buildShowChildren(name, comp, results);
        }
      });
    }
  });
  // console.log(`results:`);
  // console.log(results);
  return results;
}

// use the ingredient highlights to automatically
// expand the appropriate parents in the tree.
function buildShowChildrenFromHighlights(highlights, parents, oldShowChildren) {
  // console.log(`oldShowChildren:`);
  // console.log(oldShowChildren);
  let results = Object.assign({}, oldShowChildren);
  const highlightsPlusParents = [].concat(highlights);
  let foundNew = true;
  while (foundNew === true) {
    foundNew = false;
    for (const comp in parents) {
      for (const ing in parents[comp]) {
        if (highlightsPlusParents.includes(ing)) {
          if (!highlightsPlusParents.includes(parents[comp][ing])) {
            highlightsPlusParents.push(parents[comp][ing]);
            foundNew = true;
          }
        }
      }
    }
  }
  // console.log(`highlightsPlusParents:`);
  // console.log(highlightsPlusParents);
  highlightsPlusParents.forEach((name) => {
    for (const comp in parents) {
      results = buildShowChildrenForHighlights(name, comp, results);
    }
  });
  // console.log(`results:`);
  // console.log(results);
  return results;
}

function buildToggleChildren(name, compKey, oldShowChildren) {
  if (oldShowChildren === undefined) {
    // state.showChildren doesnt exist, so create it and populate
    return {
      [compKey]: {
        [name]: true,
      },
    };
  } else {
    if (!oldShowChildren[compKey]) {
      // state.showChildren does exist, but no data for this component
      return Object.assign({}, oldShowChildren, {
        [compKey]: {
          [name]: true,
        },
      });
    } else {
      // state.showChildren exists, as does data for the component, so
      // add new entry, or flip state if name already exists
      let newComp = null;
      const newVal = oldShowChildren[compKey][name] ? false : true;
      newComp = Object.assign({}, oldShowChildren[compKey], {
        [name]: newVal,
      });
      // console.log("newComp:");
      // console.log(newComp);
      return Object.assign({}, oldShowChildren, {
        [compKey]: newComp,
      });
    }
  }
}

// When more than one ingredient is in the search,
// find their common recipes to find commonly paired
// ingredients.
function calcCommonlyPaired(data, MIlist, MIlookup) {
  let results = [];
  const start = Date.now();
  const ingNames = Object.keys(data);
  // only calc if more than one ingredient
  if (ingNames.length > 1) {
    // start with the list of recipes for the first ingredient
    let recipes = data[ingNames[0]].recipes;
    // loop through other ingredients to pare down recipe list
    for (let i = 1; i < ingNames.length; i++) {
      const matchingRecipes = [];
      for (let j = 0; j < data[ingNames[i]].recipes.length; j++) {
        let tempData = data[ingNames[i]].recipes[j];
        for (let k = 0; k < recipes.length; k++) {
          if (recipes[k].recipe === tempData.recipe) {
            matchingRecipes.push(tempData);
          }
        }
      }
      recipes = matchingRecipes;
    }
    // console.log(`commonly paired recipes:`);
    // console.log(recipes);
    const tally = {};
    recipes.forEach((recipe) => {
      recipe.ingredients.forEach((ing) => {
        if (tally[ing] === undefined) {
          tally[ing] = 1;
        } else {
          tally[ing] += 1;
        }
      });
    });
    // console.log(`tally:`);
    // console.log(tally);
    let doNotPair = [];
    ingNames.forEach((name) => {
      doNotPair.push(name);
      doNotPair = doNotPair.concat(data[name].children);
      data[name].neverPair.forEach((neverIng) => {
        if (!doNotPair.includes(neverIng)) {
          doNotPair.push(neverIng);
        }
      });
    });
    for (const name in tally) {
      if (!doNotPair.includes(name)) {
        results.push({
          name: name,
          percent: tally[name] / recipes.length,
        });
      }
    }
    results.sort((a, b) => {
      if (a.percent !== b.percent) {
        return b.percent - a.percent;
      } else {
        return a.name.localeCompare(b.name);
      }
    });
    // console.log(`calcCommonlyPaired results:`);
    // console.log(results);
    // examine the top twenty results and remove the children in
    // any parent-child pairs
    const topResults = results.slice(0, 30);
    const topResultsNames = topResults.map((val) => {
      return val.name;
    });
    const childrenToRemove = [];
    topResultsNames.forEach((name, index) => {
      // console.log(`name: ${name}, MIlookup[name]: ${MIlookup[name]}`);
      const parentID = Object.keys(MIlist[MIlookup[name]].parent)[0];
      if (parentID !== "none") {
        const parentName = MIlist[parentID].ingredient_name;
        if (topResultsNames.includes(parentName)) {
          childrenToRemove.push(index);
        }
      }
    });
    results = topResults.filter((val, index) => {
      return !childrenToRemove.includes(index);
    });
    // console.log(`topResultsNames, childrenToRemove:`);
    // console.log(topResultsNames);
    // console.log(childrenToRemove);
  }
  console.log(`finished calcCommonlyPaired in ${Date.now() - start} ms.`);
  return results.slice(0, 10);
}

// if more than one search term, and not already found
// in database pre-built results, find the intersection of
// the search results.
function calcDishesThatInclude(dishes, searchTerms) {
  console.log(`dishes: `, dishes);
  console.log(`searchTerms: `, searchTerms);
  const results = [];
  const tempObj = {};
  dishes.recipes.forEach((recipe) => {
    const key = Object.keys(recipe)[0];
    const data = recipe[key];
    for (const dish in data.dishes) {
      if (dish !== "Soup") {
        if (tempObj[dish] === undefined) {
          tempObj[dish] = {
            recipes: [],
            recipeTotal: data.dishes[dish],
          };
        }
        if (!tempObj[dish].recipes.includes(key)) {
          tempObj[dish].recipes.push(key);
        }
      }
    }
  });
  for (const dish in tempObj) {
    results.push({
      name: dish,
      totalScore: tempObj[dish].recipes.length / tempObj[dish].recipeTotal,
    });
  }
  console.log(`calcDishesThatInclude results:`);
  console.log(results);
  return results;
}

// give each recipe's rating a point score based on the
// numerical value of the rating and the number of ratings.
function calcRecipeSortPoints(rating) {
  let sortPoints = 0;
  if (rating.length > 0) {
    const splitRating = rating.split(" ");
    const textRating = splitRating[0].split("/");
    const normalizedRating = Number(textRating[0]) / Number(textRating[1]);
    const numRatingText = splitRating[splitRating.length - 1].split("");
    numRatingText.shift();
    numRatingText.pop();
    const numRatingNumber = Number(numRatingText.join(""));
    const bonusPoints = Math.floor(numRatingNumber / 8) * 0.01;
    sortPoints = (normalizedRating + bonusPoints).toFixed(3);
  }
  return sortPoints;
}

// calculate the highlight index for the home page search.
function calcSuggestionIndex(
  currentIndex = 0,
  inputVal,
  dishes,
  ingredients,
  selectedCat
) {
  const totalResults =
    selectedCat === "DISHES" ? dishes.length : ingredients.length;
  if (inputVal === "DOWN") {
    if (currentIndex < totalResults) {
      return currentIndex + 1;
    }
  }
  if (inputVal === "UP") {
    if (currentIndex > 0) {
      return currentIndex - 1;
    }
  }
  return currentIndex;
}

// calculate the highlight index for the dish page search
function calcSuggestionIndexDish(currentIndex = 0, inputVal, ingredients) {
  const firstFalse = ingredients.findIndex((e) => e.available === false);
  const maxIndex = firstFalse === -1 ? ingredients.length : firstFalse;
  if (inputVal === "DOWN") {
    if (currentIndex < maxIndex) {
      return currentIndex + 1;
    }
  }
  if (inputVal === "UP") {
    if (currentIndex > 0) {
      return currentIndex - 1;
    }
  }
  return currentIndex;
}

// Take the array of sorted dishes and categorize them
// based on total score.
function categorizeDishesWithIngredient(dishes) {
  const categorizedDishes = {
    usually: [],
    sometimes: [],
    rarely: [],
  };
  categorizedDishes.usually = dishes.filter((dish) => {
    let belongsUsually = true;
    if (!(dish.totalScore >= 0.8)) {
      belongsUsually = false;
    }
    // for (const term in dish.totalScore) {
    //   // console.log(`usually term: ${term}`);
    //   if (!(dish.totalScore[term] >= 0.8)) {
    //     belongsUsually = false;
    //     break;
    //   }
    // }
    return belongsUsually;
  });
  categorizedDishes.sometimes = dishes.filter((dish) => {
    let belongsSometimes = true;
    if (dish.totalScore >= 0.8 || dish.totalScore < 0.3) {
      belongsSometimes = false;
    }
    // for (const term in dish.totalScore) {
    //   if (dish.totalScore[term] >= 0.8 || dish.totalScore[term] < 0.3) {
    //     belongsSometimes = false;
    //     break;
    //   }
    // }
    return belongsSometimes;
  });
  categorizedDishes.rarely = dishes.filter((dish) => {
    let belongsRarely = true;
    if (dish.totalScore >= 0.3) {
      belongsRarely = false;
    }
    // for (const term in dish.totalScore) {
    //   if (dish.totalScore[term] >= 0.3) {
    //     belongsRarely = false;
    //     break;
    //   }
    // }
    return belongsRarely;
  });
  return categorizedDishes;
}

// close all parent ingredients
function closeAllParents(parents) {
  const result = {};
  for (let comp in parents) {
    result[comp] = {};
  }
  return result;
}

// decompress miniMI data fetched from the database
function decompressMiniMI(data) {
  const json = data.miniMI;
  console.log(json);
  return data;
}

// Take the compressed data from firebase, and repopulate
// parent names from ingList.
function decompressParents(parents, ingList) {
  // console.log(`parents:`);
  // console.log(parents);
  // console.log(`ingList:`);
  // console.log(ingList);
  const dParents = {};
  for (const key in parents) {
    dParents[key] = {};
    for (const index in parents[key]) {
      dParents[key][ingList[index]] = ingList[parents[key][index]];
    }
  }
  // console.log(`decompressParents result:`);
  // console.log(dParents);
  return dParents;
}

// Take the compressed data from firebase, and repopulate
// recipes names in ingList.
function decompressRecipes(recipes, ingList) {
  const dRecipes = [];
  const populateIngs = (ings, ingList) => {
    const result = {};
    for (const key in ings) {
      result[key] = ings[key].map((item) => {
        return { name: ingList[item.n] };
      });
    }
    return result;
  };
  recipes.forEach((r) => {
    console.log(r)
    dRecipes.push({
      id: r.id,
      ings: populateIngs(r.ings, ingList),
      rating: r.rating,
      ratingToDisplay: buildRatingToDisplay(r.rating),
      sortPoints: calcRecipeSortPoints(r.rating),
      source: r.source,
      title: r.title,
      url: r.url,
    });
  });
  return dRecipes;
}

// search through the dishes to find ingredients that are
// commonly paired with the search ingredient(s)
function findCommonlyPaired(ingredients, dishes = [], MIlist, MIlookup) {
  console.log(`findCommonlyPaired:`);
  console.log(`ingredients: `, ingredients);
  console.log(`dishes:`, dishes);
  // console.log(`dishes:`, dishes);
  let tempResult = {};
  let results = [];
  if (ingredients.size && dishes.length && MIlist) {
    const neverPair = [];
    for (const ID in MIlist) {
      const data = MIlist[ID];
      if (data.noSearch) {
        neverPair.push(data.ingredient_name);
      }
    }
    console.log("neverPair");
    console.log(neverPair);
    // let neverPair = [
    //   "_pepper",
    //   "butter, oil, fats",
    //   "herbs & spices",
    //   "leaveners",
    //   "salt",
    //   "sugar & sweeteners",
    //   "vegetables",
    //   "water",
    // ];
    dishes.forEach((dish) => {
      let ingredientSum = 0;
      let ingredientMultiplier = 0;
      for (const searchIng in dish.termScores) {
        ingredientSum += dish.termScores[searchIng];
      }
      ingredientMultiplier = ingredientSum / ingredients.size;
      // console.log(`dish: ${dish.name}, ingredientSum: ${ingredientSum}, ingMultiplier: ${ingredientMultiplier}`);
      for (let name in dish.data.ingredientsSummary) {
        // don't count ingredients already in search params
        if (!ingredients.has(name)) {
          const MIkey = MIlookup[name];
          // console.log(`name: ${name}, MIkey: ${MIkey}`);
          if (!neverPair.includes(name) && !MIlist[MIkey].noMatch) {
            let percent = dish.data.ingredientsSummary[name];
            if (tempResult[name] === undefined) {
              tempResult[name] = percent * ingredientMultiplier;
            } else {
              tempResult[name] += percent * ingredientMultiplier;
            }
          }
        }
      }
    });
    for (let name in tempResult) {
      results.push({
        name: name,
        value: tempResult[name],
      });
    }
    results.sort((a, b) => b.value - a.value);
    // console.log(`commonlyPaired results:`);
    // console.log(results);
  }
  return results.slice(0, 10);
}

// Returns a Set of all ingredients searched for when
// performing search for dishesWithIngredient.
function getAllSearchedForTerms(dishes) {
  const allTerms = new Set();
  dishes.forEach((dish) => {
    for (const key in dish.termScores) {
      allTerms.add(key);
    }
  });
  return allTerms;
}

// Returns and array of search results for ingredient
// search on a dish page.
function getDishIngSearchResults(ingList, flatTree, text, component) {
  let results = [];
  let compTree = flatTree[component.k];
  const ingObjs = ingList.map((v) => {
    return { ingredient_name: v };
  });
  const trimmedText = text.trim();
  if (ingObjs && trimmedText.length > 0) {
    const options = {
      shouldSort: true,
      includeScore: true,
      includeMatches: true,
      threshold: 0.3,
      location: 0,
      distance: 30,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["ingredient_name"],
    };
    const fuse = new Fuse(ingList, options);
    const tempResults = fuse.search(trimmedText);
    // console.log(text);
    // console.log(tempResults);
    if (tempResults.length > 0) {
      // sort results by filtered, score, then alphabetically.
      tempResults.sort((a, b) => {
        a.name = a.matches[0].value;
        b.name = b.matches[0].value;
        const aAvailable = compTree.includes(a.name);
        const bAvailable = compTree.includes(b.name);
        if (aAvailable && !bAvailable) {
          return -1;
        }
        if (!aAvailable && bAvailable) {
          return 1;
        }
        if (a.score !== b.score) {
          return a.score - b.score;
        }
        return a.name.localeCompare(b.name);
      });
      tempResults.forEach((item) => {
        const name = item.matches[0].value;
        if (compTree.includes(name)) {
          results.push({ name: name, available: true });
        } else {
          results.push({ name: name, available: false });
        }
      });
      results = results.slice(0, 10);
    }
    if (results.length === 0) {
      results.push({ name: null, available: true });
    }
  }
  return results;
}

// Returns an array of search results.
function getDishSearchResults(dishList = [], text = "") {
  const results = [];
  const trimmedText = text.trim();
  if (trimmedText.length > 0 && dishList.length > 0) {
    // const firstPass = homebrewSearch(dishList, text);
    let tempResults = homebrewSearch(dishList, trimmedText);
    if (tempResults.length === 0) {
      const options = {
        shouldSort: true,
        includeScore: true,
        includeMatches: true,
        tokenize: true,
        threshold: 0.2,
        location: 0,
        distance: 32,
        maxPatternLength: 64,
        minMatchCharLength: 1,
        keys: [],
      };
      const fuse = new Fuse(dishList, options);
      tempResults = fuse.search(trimmedText);
    }
    if (tempResults.length > 0) {
      // console.log(tempResults);
      tempResults.forEach((item) => {
        results.push(item.matches[0].value);
      });
    } else results.push("_NOMATCHES");
  }
  return results;
}

// Returns an array of search results.
function getIngredientSearchResults(MIarray, text) {
  const trimmedText = text.trim();
  let results = [];
  if (MIarray && trimmedText.length > 0) {
    const options = {
      shouldSort: true,
      includeScore: true,
      includeMatches: true,
      threshold: 0.3,
      location: 0,
      distance: 30,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["ingredient_name", "alternateNames"],
    };
    const fuse = new Fuse(MIarray, options);
    const tempResults = fuse.search(trimmedText);
    // console.log(text);
    // console.log(tempResults);
    if (tempResults.length > 0) {
      results = [];
      tempResults.forEach((item) => {
        results.push(item.matches[0].value);
      });
      results = results.slice(0, 20);
    } else {
      results.push("_NOMATCHES");
    }
  }
  return results;
}

// Basic search for words spelled correctly.
function homebrewSearch(dishList, text) {
  const results = [];
  const matchedDishes = [];
  const tempText = text.toLowerCase();
  dishList.forEach((dishName) => {
    const tempName = dishName.toLowerCase();
    const index = tempName.indexOf(tempText);
    if (index !== -1) {
      results.push({
        matches: [{ value: dishName }],
        score: 0.9 - index / 100,
      });
      matchedDishes.push(dishName);
    }
  });
  // const filteredDishes = dishList.filter(name => {
  //   return !matchedDishes.includes(name);
  // });
  results.sort((a, b) => b.score - a.score);
  // console.log('homebrew results:')
  // console.log(results);
  return results;
}

// create object with all parents that are opened
function openAllParents(parents) {
  const result = {};
  for (let comp in parents) {
    result[comp] = {};
    for (let ing in parents[comp]) {
      const tempParent = parents[comp][ing];
      result[comp][tempParent] = true;
    }
  }
  return result;
}

// reset all Find a Recipe sources to show = true
function resetSources(allSources) {
  const newSources = {};
  for (const name in allSources) {
    newSources[name] = {
      count: allSources[name].count,
      only: false,
      show: true,
    };
  }
  return newSources;
}

function sortByScoreThenName(a, b) {
  if (a.totalScore !== b.totalScore) {
    return b.totalScore - a.totalScore;
  } else {
    return a.name.localeCompare(b.name);
  }
}

// toggles whether recipes for a particular source should
// be shown in the RecipeLinks component.
function toggleSource(sourceName, allSources) {
  const toggledSources = {};
  for (const name in allSources) {
    toggledSources[name] = {
      count: allSources[name].count,
      only: false,
      show: allSources[name].show,
    };
    if (name === sourceName) {
      toggledSources[name].show = !allSources[name].show;
    }
  }
  return toggledSources;
}

function main(state = {}, action) {
  switch (action.type) {
    case CLEAR_DISH_ING_SEARCH_RESULTS:
      return Object.assign({}, state, {
        dishIngredientResults: undefined,
        dishSearchBarInput: "",
        highlightedSuggestionIndexDish: 0,
      });
    case CLEAR_DW_AND_CP:
      return Object.assign({}, state, {
        commonlyPaired: null,
        dishesWithIngredient: [],
      });
    case CLEAR_SEARCH:
      return Object.assign({}, state, {
        commonlyPaired: null,
        dishesWithIngredient: null,
        highlightedSuggestionIndex: 0,
        searchResultsDishes: [],
        searchResultsIngredients: [],
        searchBarInput: "",
      });
    case CLEAR_STORE:
      return Object.assign({}, state, {
        allSources: null,
        commonlyPaired: null,
        components: null,
        dishIngredientResults: null,
        dishesWithIngredient: null,
        dishName: null,
        existingSearchTerms: [],
        highlightedSuggestionIndex: 0,
        highlightedSuggestionIndexDish: 0,
        flatTree: null,
        filtersShow: false,
        ingredientFilters: [],
        ingredientHighlights: [],
        ingredientHighlightsApplied: [],
        ingredientTree: null,
        recipes: null,
        recipesToShow: null,
        searchResultsDishes: [],
        searchResultsIngredients: [],
        searchBarInput: "",
        showChildren: {},
        showDishOptionsInfo: false,
        showDishPageIngSearch: false,
        showFilterBySource: false,
        showNavMenu: false,
        selectedHomeSearchResults: "DISHES",
        showSearchResults: false,
      });
    case CLOSE_ALL_PARENTS:
      return Object.assign({}, state, {
        showChildren: closeAllParents(state.parents),
      });
    case CLOSE_DISH_ING_SEARCH_RESULTS:
      return Object.assign({}, state, {
        dishIngredientResults: undefined,
        // dishSearchBarInput: "",
        highlightedSuggestionIndexDish: 0,
      });
    case FIND_COMMONLY_PAIRED:
      // const usuallyAndSometimes = state.dishesWithIngredient.filter(dish => {
      //   return dish.totalScore >= 0.3;
      // });
      return Object.assign({}, state, {
        commonlyPaired: findCommonlyPaired(
          state.ALLsearchedForTerms,
          state.dishesWithIngredient,
          state.MIlist,
          state.MIlookup
        ),
        existingSearchTerms: action.searchTerms,
        searchBarInput: "",
        highlightedSuggestionIndex: 0,
      });
    case KEYBOARD_HIGHLIGHT_SEARCH_SUGGESTION:
      return Object.assign({}, state, {
        highlightedSuggestionIndex: calcSuggestionIndex(
          state.highlightedSuggestionIndex,
          action.val,
          state.searchResultsDishes,
          state.searchResultsIngredients,
          state.selectedHomeSearchResults
        ),
      });
    case KEYBOARD_HIGHLIGHT_SEARCH_SUGGESTION_DISH:
      return Object.assign({}, state, {
        highlightedSuggestionIndexDish: calcSuggestionIndexDish(
          state.highlightedSuggestionIndexDish,
          action.val,
          state.dishIngredientResults
        ),
      });
    case KEYBOARD_SELECT_SEARCH_SUGGESTION:
      return Object.assign({}, state, {});
    case OPEN_ALL_PARENTS:
      return Object.assign({}, state, {
        showChildren: openAllParents(state.parents),
      });
    case REBUILD_TREE:
      // const sourceRecipes = treeHelpers.applySourceFilters(
      //   state.recipes,
      //   state.allSources
      // );
      // first apply ingredient filters and then build sources
      // with know which sources need ingredient-filter lockout.
      const rebuildRecipes = treeHelpers.applyIngredientFilters(
        state.recipes,
        state.parents,
        state.ingredientFilters
      );
      const rebuildSources = buildRecipeSources(
        rebuildRecipes,
        state.allSources
      );
      const sourceFilterRecipes = treeHelpers.applySourceFilters(
        rebuildRecipes,
        state.allSources
      );
      const rebuildParents = treeHelpers.buildWorkingParents(
        sourceFilterRecipes,
        state.parents
      );
      const rebuildTree = treeHelpers.buildTree(
        sourceFilterRecipes,
        rebuildParents
      );
      const rebuildShowChildren = buildShowChildrenFromFilters(
        state.ingredientFilters,
        rebuildParents,
        state.showChildren
      );
      return Object.assign({}, state, {
        flatTree: treeHelpers.flattenTree(rebuildTree),
        ingredientTree: rebuildTree,
        showChildren: rebuildShowChildren,
        recipesToShow: buildRecipesToShow(rebuildRecipes, state.allSources),
        workingRecipes: sourceFilterRecipes,
        workingSources: rebuildSources,
      });
    case RESET_SOURCES:
      const rSources = resetSources(state.allSources);
      console.log("rSources: ", rSources);
      const resetRecipes = buildRecipesToShow(state.workingRecipes, rSources);
      return Object.assign({}, state, {
        allSources: rSources,
        allSourcesHistory: undefined,
        recipesToShow: resetRecipes,
      });
    case SELECT_COMPONENT:
      return Object.assign({}, state, {
        selectedComponent: action.component,
      });
    case SET_HIGHLIGHTED_SUGGESTION_INDEX:
      return Object.assign({}, state, {
        highlightedSuggestionIndex: action.num,
      });
    case SELECT_HOME_SEARCH_RESULTS:
      return Object.assign({}, state, {
        selectedHomeSearchResults: action.name,
        highlightedSuggestionIndex: 0,
      });
    case START_LOCKOUT:
      return Object.assign({}, state, {
        lockoutCommonlyPaired: true,
        lockoutDishes: true,
      });
    case TOGGLE_CHILDREN:
      // const showChildrenKey = `showChildren_${action.compKey}`;
      const newShowChildren = buildToggleChildren(
        action.ingName,
        action.compKey,
        state.showChildren
        // state[showChildrenKey]
      );
      return Object.assign({}, state, {
        showChildren: newShowChildren,
      });
    case TOGGLE_DISH_OPTIONS_INFO:
      const newShowDishOptionsInfo = state.showDishOptionsInfo ? false : true;
      return Object.assign({}, state, {
        showDishOptionsInfo: newShowDishOptionsInfo,
      });
    case TOGGLE_DISH_PAGE_ING_SEARCH:
      return Object.assign({}, state, {
        showDishPageIngSearch: !state.showDishPageIngSearch,
      });
    case TOGGLE_FILTERS:
      return Object.assign({}, state, {
        filtersShow: !state.filtersShow,
      });
    case TOGGLE_FILTER_BY_SOURCE:
      return Object.assign({}, state, {
        showFilterBySource: !state.showFilterBySource,
      });
    case TOGGLE_INGREDIENT_INFO:
      const newShowIngredientInfo = state.showIngredientInfo ? false : true;
      return Object.assign({}, state, {
        showIngredientInfo: newShowIngredientInfo,
      });
    case TOGGLE_NAV_MENU:
      return Object.assign({}, state, {
        showNavMenu: !state.showNavMenu,
      });
    case TOGGLE_ONLY_SOURCE:
      const newBuildOnly = buildOnlySources(
        action.name,
        state.allSources,
        state.allSourcesHistory
      );
      const onlyRecipes = buildRecipesToShow(
        state.workingRecipes,
        newBuildOnly.sources
      );
      return Object.assign({}, state, {
        allSources: newBuildOnly.sources,
        allSourcesHistory: newBuildOnly.history,
        recipesToShow: onlyRecipes,
      });
    case TOGGLE_SEARCH_RESULTS:
      return Object.assign({}, state, {
        showSearchResults: action.val,
      });
    case TOGGLE_SOURCE:
      const toggledAllSources = toggleSource(
        action.sourceName,
        state.allSources
      );
      const toggledRecipes = buildRecipesToShow(
        state.workingRecipes,
        toggledAllSources
      );
      return Object.assign({}, state, {
        allSources: toggledAllSources,
        allSourcesHistory: undefined,
        recipesToShow: toggledRecipes,
      });
    case UPDATE_COMMONLY_PAIRED_PAGE:
      return Object.assign({}, state, {
        commonlyPairedPage: action.nextPage,
      });
    case UPDATE_COMMONLY_PAIRED_PRIMARY:
      const tempKeys = Object.keys(action.data);
      // if only one search term
      if (tempKeys.length === 1) {
        return Object.assign({}, state, {
          commonlyPaired: action.data[tempKeys[0]],
          lockoutCommonlyPaired: false,
        });
      } else {
        return state;
      }
    case UPDATE_COMMONLY_PAIRED_SECONDARY:
      const secondaryTempPaired = calcCommonlyPaired(
        action.data,
        state.MIlist,
        state.MIlookup
      );
      if (secondaryTempPaired.length > 0) {
        return Object.assign({}, state, {
          commonlyPaired: secondaryTempPaired,
          lockoutCommonlyPaired: false,
        });
      } else {
        return state;
      }
    case UPDATE_CONFIRM_MSG:
      if (action.val === "SUCCESS") {
        return Object.assign({}, state, {
          contactMsgConfirm: action.val,
          contactEmail: "",
          contactMessage: "",
        });
      } else {
        return Object.assign({}, state, {
          contactMsgConfirm: action.val,
        });
      }
    case UPDATE_CONTACT_EMAIL:
      return Object.assign({}, state, {
        contactEmail: action.val,
        contactMsgConfirm: null,
      });
    case UPDATE_CONTACT_MESSAGE:
      return Object.assign({}, state, {
        contactMessage: action.val,
        contactMsgConfirm: null,
      });
    case UPDATE_DEBUG_WINDOW:
      return Object.assign({}, state, {
        debugWindow: action.text,
      });
    // case UPDATE_DISH:
    //   return Object.assign({}, state, {
    //     dishName: action.dishName,
    //     ingredientTree: action.data.compressedTree,
    //     // recipeList: getRecipesFromTree(action.data.ingredientTree),
    //   });
    case UPDATE_DISH_COMPRESSED:
      const start = Date.now();
      const pComps = JSON.parse(action.data.components);
      const pIngList = JSON.parse(action.data.ingList);
      const pParents = JSON.parse(action.data.parents);
      const dParents = decompressParents(pParents, pIngList);
      const pRecipes = JSON.parse(action.data.recipes);
      const dRecipes = decompressRecipes(pRecipes, pIngList);
      const middle = Date.now();
      const decompressTime = middle - start;
      const allSources = buildAllSources(dRecipes);
      const workingRecipes = treeHelpers.applyIngredientFilters(
        dRecipes,
        dParents,
        state.ingredientFilters
      );
      const workingParents = treeHelpers.buildWorkingParents(
        workingRecipes,
        dParents
      );
      const appliedHighlightsD = buildHighlightsFromMI(
        state.ingredientHighlights,
        state.MIlist,
        state.MIlookup
      );
      // const appliedHighlightsD = state.MIlist
      // ? buildHighlightsFromMI(state.ingredientHighlights, state.MIlist, state.MIlookup)
      // : buildHighlightsFromIngList(
      //     state.ingredientHighlights,
      //     pIngList,
      //     dParents
      //   );
      const showChildren2 = buildShowChildren2(
        state.ingredientFilters,
        state.ingredientHighlights,
        workingParents,
        state.showChildren
      );
      let showFilters = false;
      if (
        state.ingredientFilters !== undefined &&
        state.ingredientFilters.length > 0
      ) {
        showFilters = true;
      }
      const tree = treeHelpers.buildTree(workingRecipes, workingParents);
      const workingSources = buildRecipeSources(workingRecipes, allSources);
      const treeTime = Date.now() - middle;
      // console.log(`time to decompress: ${decompressTime} ms`);
      // console.log(`time to build tree: ${treeTime} ms`);
      return Object.assign({}, state, {
        allSources: allSources,
        components: pComps,
        decompressTime: decompressTime,
        dishName: action.dishName,
        flatTree: treeHelpers.flattenTree(tree),
        ingList: pIngList,
        ingredientHighlightsApplied: appliedHighlightsD,
        ingredientTree: tree,
        parents: dParents,
        recipes: dRecipes,
        recipesToShow: buildRecipesToShow(workingRecipes, allSources),
        searchAndFiltersShow: showFilters,
        selectedComponent: pComps[0],
        showChildren: showChildren2,
        showDishPageIngSearch: false,
        treeTime: treeTime,
        workingRecipes: workingRecipes,
        workingSources: workingSources,
      });
    case UPDATE_DISH_INGREDIENT_SEARCH:
      return Object.assign({}, state, {
        dishIngredientResults: getDishIngSearchResults(
          state.ingList,
          state.flatTree,
          state.dishSearchBarInput,
          state.selectedComponent
        ),
      });
    case UPDATE_DISH_LIST:
      const tempSearchTermD = state.searchBarInput || "";
      return Object.assign({}, state, {
        dishList: action.dishList,
        searchResultsDishes: getDishSearchResults(
          action.dishList,
          tempSearchTermD
        ),
      });
    case UPDATE_DISH_SEARCH_BAR_INPUT:
      return Object.assign({}, state, {
        dishSearchBarInput: action.value.toLowerCase(),
        highlightedSuggestionIndexDish: 0,
      });
    case UPDATE_DISH_SEARCH_WIDTH:
      return Object.assign({}, state, {
        dishSearchWidth: action.styleObj,
      });
    case UPDATE_DISHES_WITH_INGREDIENT:
      // check if multiple ingredients to be searched for
      const sortedDishes =
        action.searchTerms.length === 1
          ? action.dishes[action.searchTerms[0]]
          : calcDishesThatInclude(action.dishes, action.searchTerms);
      sortedDishes.sort(sortByScoreThenName);
      console.log(`sortedDishes:`);
      console.log(sortedDishes);
      const categorizedDishes = categorizeDishesWithIngredient(sortedDishes);
      const allTerms = getAllSearchedForTerms(sortedDishes);
      return Object.assign({}, state, {
        ALLsearchedForTerms: allTerms,
        categorizedDishes: categorizedDishes,
        dishesWithIngredient: sortedDishes,
        rawDishesWithResults: action.dishes,
        lockoutDishes: false,
      });
    case UPDATE_EXISTING_SEARCH_TERMS:
      return Object.assign({}, state, {
        existingSearchTerms: action.terms,
        searchBarInput: "",
      });
    case UPDATE_HOME_SEARCH_WIDTH:
      return Object.assign({}, state, {
        homeSearchWidth: action.styleObj,
      });
    case UPDATE_INGREDIENT_FILTERS:
      return Object.assign({}, state, {
        ingredientFilters: action.terms,
      });
    case UPDATE_INGREDIENT_HIGHLIGHTS:
      const appliedHighlights = state.MIlist
        ? buildHighlightsFromMI(action.terms, state.MIlist, state.MIlookup)
        : buildHighlightsFromIngList(
            action.terms,
            state.ingList,
            state.parents
          );
      const showChildrenHighlights = buildShowChildrenFromHighlights(
        appliedHighlights,
        state.parents,
        state.showChildren
      );
      return Object.assign({}, state, {
        ingredientHighlights: action.terms,
        ingredientHighlightsApplied: appliedHighlights,
        showChildren: showChildrenHighlights,
      });
    case UPDATE_INGREDIENT_SEARCH_DISHES:
      const ingredientSearchKey = `ingredientSearch_${action.frequency}`;
      return Object.assign({}, state, {
        [ingredientSearchKey]: action.dishes,
      });
    case UPDATE_MASTER_INGREDIENTS:
      const MIarray = buildMIarray(action.masterIngredients);
      const MIlookup = buildMIlookupObj(action.masterIngredients);
      const MIsearch = buildMIsearch(action.masterIngredients);
      return Object.assign({}, state, {
        MIarray: MIarray,
        MIlist: action.masterIngredients,
        MIlookup: MIlookup,
        MIsearch: MIsearch,
      });
    case UPDATE_MINI_MI:
      console.log("got a mini MI:");
      console.log(action.data);
      const miniMI = decompressMiniMI(action.data);
      return Object.assign({}, state, {
        miniMI: miniMI,
      });
    case UPDATE_SEARCH_BAR_INPUT:
      return Object.assign({}, state, {
        searchBarInput: action.value.toLowerCase(),
        highlightedSuggestionIndex: 0,
      });
    case UPDATE_SEARCH_RESULTS_DISHES:
      return Object.assign({}, state, {
        searchResultsDishes: getDishSearchResults(
          state.dishList,
          state.searchBarInput
        ),
      });
    case UPDATE_SEARCH_RESULTS_INGREDIENTS:
      return Object.assign({}, state, {
        searchResultsIngredients: getIngredientSearchResults(
          state.MIsearch,
          state.searchBarInput
        ),
      });
    default:
      return state;
  }
}

export default main;
