// NotFound.js

import React from "react";

import "../css/NotFound.css";

const NotFound = () => {
  // window.scrollTo({ top: 0, left: 0, behavior: "auto" });

  return (
    <div>
      <div id="notFoundMsg">
        This web address doesn't match a page on our site.
      </div>
    </div>
  );
};

export default NotFound;
