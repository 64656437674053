// CommonlyPaired.js
// css in Home.css

import React from "react";

const CommonlyPaired = (props) => {
  const existingSearchTerms = props.existingSearchTerms || [];
  const commonlyPaired = props.commonlyPaired || [];
  const cpTop = commonlyPaired.filter((v, i) => i % 2 === 0);
  const cpBottom = commonlyPaired.filter((v, i) => i % 2 === 1);
  const lockoutClass = props.lockout ? "lockoutCP" : "";

  const addIngredientToSearch = (value) => {
    if (!props.lockout) {
      console.log(`adding search term: ${value}`);
      const encodedVal = encodeURIComponent(value);
      const newSearchTerms = existingSearchTerms.concat([encodedVal]);
      const newURL = `/search?q=${newSearchTerms.join("+")}`;
      props.history.push(newURL);
    }
  };

  const renderIngredient = (value, key) => {
    return (
      <div
        key={key}
        className="commonlyIngredient"
        onClick={addIngredientToSearch.bind(null, value.name)}
      >
       {value.name} 
       {/* {value.percent.toFixed(2)} */}
      </div>
    );
  };

  // let rightSlideButton = null;
  // if (pairedPage + 1 < totalPages) {
  //   rightSlideButton = (
  //     <span
  //       className="material-icons"
  //       onClick={props.updateCommonlyPairedPage.bind(null, pairedPage + 1)}
  //     >
  //       arrow_forward_ios
  //     </span>
  //   );
  // }

  // let leftSlideButton = null;
  // if (pairedPage > 0) {
  //   leftSlideButton = (
  //     <span
  //       className="material-icons"
  //       onClick={props.updateCommonlyPairedPage.bind(null, pairedPage - 1)}
  //     >
  //       arrow_back_ios
  //     </span>
  //   );
  // }

  return (
    <div id="commonly">
      <h3>Common pairings:</h3>
      <div id="optionsSlider">
        <div>{cpTop.map(renderIngredient)}</div>
        <div>{cpBottom.map(renderIngredient)}</div>
      </div>
      <div className={lockoutClass} />
    </div>
  );
};

export default CommonlyPaired;
