// RecipeSources.js

// RecipeLinks.js

import React from "react";

import "../css/RecipeSources.css";

const RecipeSources = ({
  allSources,
  workingSources,
  toggleOnlySource,
  toggleSource,
}) => {
  const renderSources = (source, key) => {
    const allCount = allSources[source.name].count;
    const countText =
      source.count === allCount
        ? `${source.count}`
        : `${source.count} of ${allCount}`;
    if (source.lockout === true) {
      // show the source, but don't let user interact
      return (
        <div className="source" key={key}>
          <input type="checkbox" name={source.name} checked={false} readOnly />
          <div className="sourceName sourceLockedOut">
            {source.name} ({countText.replace("0 of ", "")})
          </div>
        </div>
      );
    } else {
      // if the source hasn't already been filtered out
      // by ingredient filters.
      const checked = allSources[source.name].show;
      const onlyText = allSources[source.name].only ? "cancel" : "only";
      return (
        <div className="source" key={key}>
          <input
            type="checkbox"
            name={source.name}
            checked={checked}
            onChange={toggleSource.bind(null, source.name)}
          />
          <div className="sourceName">
            {source.name} ({countText})
          </div>
          <div
            className="onlyButton"
            onClick={toggleOnlySource.bind(null, source.name)}
          >
            {onlyText}
          </div>
        </div>
      );
    }
  };

  return (
    <div id="recipeSourcesContainer">{workingSources.map(renderSources)}</div>
  );
};

export default RecipeSources;
