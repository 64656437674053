// firebase.js
// Reducers for Firebase-related actions.

import { 
	ADD_FIREBASE
} from '../actions/actionCreators';



function firebase(state = {}, action) {
	switch ( action.type ) {
		case ADD_FIREBASE :
			return Object.assign({}, state, {
				[action.firebase.name]: action.firebase
			});
		default :
			return state;
	}
}


export default firebase;