// About_OLD.js
// Ditched DEC 29th 2021 because we're just doing soups.

import React from "react";

import "../css/WhatIsSifted.css";

const WhatIsSifted = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "auto" });

  return (
    <div>
      <div id="aboutWrapper">
        <div id="aboutContent">
          <h1>What is sifted.recipes?</h1>
          <h4>A different type of recipe search.</h4>

          <p>
            Do you ever read three or four recipes just to get a sense of what
            normally goes in a dish? We streamline that for you by compiling
            recipe data for common dishes.
          </p>

          <h3>Our data can answer questions like these:</h3>

          <div className="aboutQuestion">
            <div className="wondering">
              Do I have everything I need to make a borscht?
            </div>
            <p>
              Find out what goes in a dish at a glance. See what's normal and
              what's an outlier. No need to open all those tabs to decide if you
              can, or want, to make a dish.
            </p>
          </div>

          <div className="aboutQuestion">
            <div className="wondering">
              I don't like mushrooms... how crucial are they to beef
              bourguignon?
            </div>
            <p>
              The data gives you confidence in making changes to recipes, you’ll
              learn what is at the heart of each dish before making a
              substitution or leaving ingredients out.
            </p>
          </div>

          <div className="aboutQuestion">
            <div className="wondering">
              I'm tired of my go-to chili recipe. Is there a different flavor
              I'm missing out on?
            </div>
            <p>
              The data doesn't just show you the commonplace. You can also find
              ingredients only sometimes or rarely used, helping you put a twist
              on a traditional dish.
            </p>
          </div>
          <br />
          <div>
            Our data will help you decide what to make, show you recipe links
            based on your ingredient filters, and give you confidence to make
            substitutions (or to cook without a recipe at all). Whether you're a
            recipe-follower or recipe-tweaker, we think sifted.recipes is the
            place to start.
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIsSifted;
