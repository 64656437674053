import { combineReducers } from 'redux';

import firebase from './firebase';
import main from './main';

const rootReducer = combineReducers({
	firebase,
	main
});

export default rootReducer;