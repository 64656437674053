// RecipeLinks.js

import React from "react";

import "../css/RecipeLinks.css";

const RecipeLinks = ({ recipes }) => {
  const getBonusWhitespace = () => {
    // get pixel height of all recipes
    const recipesHeight = recipes.length * 67;
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    const sourcesElement = document.getElementById("recipeSourcesContainer");
    const sourcesBottom =
      sourcesElement !== null
        ? sourcesElement.getBoundingClientRect().bottom
        : null;
    const linksContainerPadding = 16;
    const footer = 90;
    const spaceToFill = vh - sourcesBottom - linksContainerPadding - footer;
    // console.log("vh:", vh, "sourcesBottom:", sourcesBottom, "recipesHeight: ", recipesHeight, "spaceToFill: ", spaceToFill);
    if (recipesHeight < spaceToFill) {
      return { height: spaceToFill };
    } else {
      return {};
    }
  };

  const renderRecipeLinks = (recipe, key) => {
    // const numReviews =
    //   recipe.rating.length > 0 ? recipe.rating[recipe.rating.length - 2] : "0";
    // let ratingHack = "";
    // if (numReviews !== "0") {
    //   ratingHack = ` - ${recipe.rating.replace(")", " reviews)")}`;
    // }
    return (
      <div key={key} className="recipeLink">
        <div className="sourceLink">
          <a href={recipe.url} target="_self" rel="noopener noreferrer">
            {recipe.title}
            {/* <span className="material-icons">open_in_new</span> */}
          </a>
        </div>
        <div className="sourceSite">
          <div>{recipe.source}</div>
          <div>{recipe.ratingToDisplay}</div>
        </div>
      </div>
    );
  };

  return (
    <div id="recipeLinks" style={getBonusWhitespace()}>
      {recipes.map(renderRecipeLinks)}
    </div>
  );
};

export default RecipeLinks;
