// actionCreators.js

// for adding a Firebase database
export const ADD_FIREBASE = "ADD_FIREBASE";
export function addFirebase(firebase) {
  return {
    type: ADD_FIREBASE,
    firebase,
  };
}

// // from the ingredient tree, build the list of recipes.
// export const BUILD_RECIPE_LIST = "BUILD_RECIPE_LIST";
// export function buildRecipeList() {
//   return {
//     type: BUILD_RECIPE_LIST
//   };
// }

// for clearing the ingredient search results on a dish page
export const CLEAR_DISH_ING_SEARCH_RESULTS = "CLEAR_DISH_ING_SEARCH_RESULTS";
export function clearDishIngSearchResults() {
  return {
    type: CLEAR_DISH_ING_SEARCH_RESULTS,
  };
}

// for clearing results in dishesWithIngredient and commonlyPaired
export const CLEAR_DW_AND_CP = "CLEAR_DW_AND_CP";
export function clearDWandCP() {
  return {
    type: CLEAR_DW_AND_CP,
  };
}

// reset everything to do with the search
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}

// reset the app, nuke all data for a specific dish in the store
export const CLEAR_STORE = "CLEAR_STORE";
export function clearStore() {
  return {
    type: CLEAR_STORE,
  };
}

// close all ingredient parents in the tree
export const CLOSE_ALL_PARENTS = "CLOSE_ALL_PARENTS";
export function closeAllParents() {
  return {
    type: CLOSE_ALL_PARENTS,
  };
}

// close the search results on the dish page without clearing
// the search bar input
export const CLOSE_DISH_ING_SEARCH_RESULTS = "CLOSE_DISH_ING_SEARC_RESULTS";
export function closeDishIngSearchResults() {
  return {
    type: CLOSE_DISH_ING_SEARCH_RESULTS,
  };
}

// find ingredients commonly paired with the search term(s)
export const FIND_COMMONLY_PAIRED = "FIND_COMMONLY_PAIRED";
export function findCommonlyPaired(searchTerms) {
  return {
    type: FIND_COMMONLY_PAIRED,
    searchTerms,
  };
}

// for using the keyboard to highlight a search suggestion
// on the home page.
export const KEYBOARD_HIGHLIGHT_SEARCH_SUGGESTION =
  "KEYBOARD_HIGHLIGHT_SEARCH_SUGGESTION";
export function keyboardHighlightSearchSuggestion(val) {
  return {
    type: KEYBOARD_HIGHLIGHT_SEARCH_SUGGESTION,
    val,
  };
}

// for using the keyboard to highlight a search suggestion
// on a dish page.
export const KEYBOARD_HIGHLIGHT_SEARCH_SUGGESTION_DISH =
  "KEYBOARD_HIGHLIGHT_SEARCH_SUGGESTION_DISH";
export function keyboardHighlightSearchSuggestionDish(val) {
  return {
    type: KEYBOARD_HIGHLIGHT_SEARCH_SUGGESTION_DISH,
    val,
  };
}

// for using the keyboard to select a search suggestion, or the search input
export const KEYBOARD_SELECT_SEARCH_SUGGESTION =
  "KEYBOARD_SELECT_SEARCH_SUGGESTION";
export function keyboardSelectSearchSuggestion() {
  return {
    type: KEYBOARD_SELECT_SEARCH_SUGGESTION,
  };
}

// open all ingredient parents in the tree
export const OPEN_ALL_PARENTS = "OPEN_ALL_PARENTS";
export function openAllParents() {
  return {
    type: OPEN_ALL_PARENTS,
  };
}

// rebuild the tree after some filters have been applied
export const REBUILD_TREE = "REBUILD_TREE";
export function rebuildTree() {
  return {
    type: REBUILD_TREE,
  };
}

// reset all sources to show=true in RecipeSources
export const RESET_SOURCES = "RESET_SOURCES";
export function resetSources() {
  return {
    type: RESET_SOURCES,
  };
}

// for selecting the component to show
export const SELECT_COMPONENT = "SELECT_COMPONENT";
export function selectComponent(component) {
  return {
    type: SELECT_COMPONENT,
    component,
  };
}

// select the dishes or ingredient tab in the main search bar results
export const SELECT_HOME_SEARCH_RESULTS = "SELECT_HOME_SEARCH_RESULTS";
export function selectHomeSearchResults(name) {
  return {
    type: SELECT_HOME_SEARCH_RESULTS,
    name,
  };
}

// directly set the value for the highlightedSuggestionIndex
export const SET_HIGHLIGHTED_SUGGESTION_INDEX =
  "SET_HIGHLIGHTED_SUGGESTION_INDEX";
export function setHighlightedSuggestionIndex(num) {
  return {
    type: SET_HIGHLIGHTED_SUGGESTION_INDEX,
    num,
  };
}

// for setting whether or not commonly paired and dish results are
// available for interaction.  Use to block interaction while data is
// processing.
export const START_LOCKOUT = "START_LOCKOUT";
export function startLockout() {
  return {
    type: START_LOCKOUT,
  };
}

// toggle children showing for an ingredient in the tree
export const TOGGLE_CHILDREN = "TOGGLE_CHILDREN";
export function toggleChildren(ingName, compKey) {
  return {
    type: TOGGLE_CHILDREN,
    ingName,
    compKey,
  };
}

// toggle the popup info in the DishesThatInclude section
export const TOGGLE_DISH_OPTIONS_INFO = "TOGGLE_DISH_OPTIONS_INFO";
export function toggleDishOptionsInfo() {
  return {
    type: TOGGLE_DISH_OPTIONS_INFO,
  };
}

//  16JAN2022 DOES THIS EVEN EXIST ANYMORE??
// toggle the seach bar at the top of an ingredients list
// on a dish page
export const TOGGLE_DISH_PAGE_ING_SEARCH = "TOGGLE_DISH_PAGE_ING_SEARCH";
export function toggleDishPageIngSearch() {
  return {
    type: TOGGLE_DISH_PAGE_ING_SEARCH,
  };
}

// toggle the dish page ingredient search bar, plus filter
// status box and filter buttons.
export const TOGGLE_FILTERS = "TOGGLE_FILTERS";
export function toggleFilters() {
  return {
    type: TOGGLE_FILTERS,
  };
}

// toggle the dish page source filters
export const TOGGLE_FILTER_BY_SOURCE = "TOGGLE_FILTER_BY_SOURCE";
export function toggleFilterBySource() {
  return {
    type: TOGGLE_FILTER_BY_SOURCE,
  };
}

// toggle the popup info in the Dish page ingredient tree section
export const TOGGLE_INGREDIENT_INFO = "TOGGLE_INGREDIENT_INFO";
export function toggleIngredientInfo() {
  return {
    type: TOGGLE_INGREDIENT_INFO,
  };
}

// toggle the nav menu in the header from the burger.
export const TOGGLE_NAV_MENU = "TOGGLE_NAV_MENU";
export function toggleNavMenu() {
  return {
    type: TOGGLE_NAV_MENU
  }
}

// toggle a recipe source to be the only source for recipe search
export const TOGGLE_ONLY_SOURCE = "TOGGLE_ONLY_SOURCE";
export function toggleOnlySource(name) {
  return {
    type: TOGGLE_ONLY_SOURCE,
    name,
  };
}

// for toggling the search results on the home page
export const TOGGLE_SEARCH_RESULTS = "TOGGLE_SEARCH_RESULTS";
export function toggleSearchResults(val) {
  return {
    type: TOGGLE_SEARCH_RESULTS,
    val,
  };
}

// DEPRECATED NOV 2021
// // toggle whether or not all the ingredients are showing
// export const TOGGLE_SHOW_MORE = "TOGGLE_SHOW_MORE";
// export function toggleShowMore() {
//   return {
//     type: TOGGLE_SHOW_MORE,
//   };
// }

// toggle whether or not recipes from a particular source
// are included in the results.
export const TOGGLE_SOURCE = "TOGGLE_SOURCE";
export function toggleSource(sourceName) {
  return {
    type: TOGGLE_SOURCE,
    sourceName,
  };
}

// // for setting the working dish
// export const UPDATE_DISH = "UPDATE_DISH";
// export function updateDish(dishName, data) {
//   return {
//     type: UPDATE_DISH,
//     dishName,
//     data,
//   };
// }

// for setting the commonly paired data - primary
export const UPDATE_COMMONLY_PAIRED_PAGE = "UPDATE_COMMONLY_PAIRED_PAGE";
export function updateCommonlyPairedPage(nextPage) {
  return {
    type: UPDATE_COMMONLY_PAIRED_PAGE,
    nextPage,
  };
}

// for setting the commonly paired data - primary
export const UPDATE_COMMONLY_PAIRED_PRIMARY = "UPDATE_COMMONLY_PAIRED_PRIMARY";
export function updateCommonlyPairedPrimary(data) {
  return {
    type: UPDATE_COMMONLY_PAIRED_PRIMARY,
    data,
  };
}

// for setting the commonly paired data - secondary
export const UPDATE_COMMONLY_PAIRED_SECONDARY =
  "UPDATE_COMMONLY_PAIRED_SECONDARY";
export function updateCommonlyPairedSecondary(data) {
  return {
    type: UPDATE_COMMONLY_PAIRED_SECONDARY,
    data,
  };
}

// update the store to know a contact message was successfully sent
export const UPDATE_CONFIRM_MSG = "UPDATE_CONFIRM_MSG";
export function updateConfirmMsg(val) {
  return {
    type: UPDATE_CONFIRM_MSG,
    val,
  };
}

// updates the email field on the contact page
export const UPDATE_CONTACT_EMAIL = "UPDATE_CONTACT_EMAIL";
export function updateContactEmail(e) {
  return {
    type: UPDATE_CONTACT_EMAIL,
    val: e.target.value,
  };
}

// updates the message field on the contact page
export const UPDATE_CONTACT_MESSAGE = "UPDATE_CONTACT_MESSAGE";
export function updateContactMessage(e) {
  return {
    type: UPDATE_CONTACT_MESSAGE,
    val: e.target.value,
  };
}

// for updating the contents of the debug window on the
// Dish component, when enabled for mobile debugging.
export const UPDATE_DEBUG_WINDOW = "UPDATE_DEBUG_WINDOW";
export function updateDebugWindow(text) {
  return {
    type: UPDATE_DEBUG_WINDOW,
    text,
  };
}

// for setting the working dish
export const UPDATE_DISH_COMPRESSED = "UPDATE_DISH_COMPRESSED";
export function updateDishCompressed(dishName, data) {
  return {
    type: UPDATE_DISH_COMPRESSED,
    dishName,
    data,
  };
}

// updates the results of new values in the ingredient seach
// on a dish page.
export const UPDATE_DISH_INGREDIENT_SEARCH = "UPDATE_DISH_INGREDIENT_SEARCH";
export function updateDishIngredientSearch() {
  return {
    type: UPDATE_DISH_INGREDIENT_SEARCH,
  };
}

// for receiving the list of dish files
export const UPDATE_DISH_LIST = "UPDATE_DISH_LIST";
export function updateDishList(dishList) {
  return {
    type: UPDATE_DISH_LIST,
    dishList,
  };
}

// update the search term in the homepage search
export const UPDATE_DISH_SEARCH_BAR_INPUT = "UPDATE_DISH_SEARCH_BAR_INPUT";
export function updateDishSearchBarInput(value) {
  return {
    type: UPDATE_DISH_SEARCH_BAR_INPUT,
    value,
  };
}

// update the width value of the dish page search bar
export const UPDATE_DISH_SEARCH_WIDTH = "UPDATE_DISH_SEARCH_WIDTH";
export function updateDishSearchWidth(styleObj) {
  return {
    type: UPDATE_DISH_SEARCH_WIDTH,
    styleObj,
  };
}

// for the list of dishes that include the searched-for ingredient
export const UPDATE_DISHES_WITH_INGREDIENT = "UPDATE_DISHES_WITH_INGREDIENT";
export function updateDishesWithIngredient(dishes, searchTerms) {
  return {
    type: UPDATE_DISHES_WITH_INGREDIENT,
    dishes,
    searchTerms,
  };
}

// update the array of existing search terms from the user
export const UPDATE_EXISTING_SEARCH_TERMS = "UPDATE_EXISTING_SEARCH_TERMS";
export function updateExistingSearchTerms(terms) {
  return {
    type: UPDATE_EXISTING_SEARCH_TERMS,
    terms,
  };
}

// update the width value of the home search bar
export const UPDATE_HOME_SEARCH_WIDTH = "UPDATE_HOME_SEARCH_WIDTH";
export function updateHomeSearchWidth(styleObj) {
  return {
    type: UPDATE_HOME_SEARCH_WIDTH,
    styleObj,
  };
}

// add filter terms to a dish
export const UPDATE_INGREDIENT_FILTERS = "UPDATE_INGREDIENT_FILTERS";
export function updateIngredientFilters(terms) {
  return {
    type: UPDATE_INGREDIENT_FILTERS,
    terms,
  };
}

// add highlight terms to a dish
export const UPDATE_INGREDIENT_HIGHLIGHTS = "UPDATE_INGREDIENT_HIGHLIGHTS";
export function updateIngredientHighlights(terms) {
  return {
    type: UPDATE_INGREDIENT_HIGHLIGHTS,
    terms,
  };
}

// for dishes returned from ingredient search
export const UPDATE_INGREDIENT_SEARCH_DISHES =
  "UPDATE_INGREDIENT_SEARCH_DISHES";
export function updateIngredientSearchDishes(dishes, frequency) {
  return {
    type: UPDATE_INGREDIENT_SEARCH_DISHES,
    dishes,
    frequency,
  };
}

// for updating the Master Ingredients list
export const UPDATE_MASTER_INGREDIENTS = "UPDATE_MASTER_INGREDIENTS";
export function updateMasterIngredients(json) {
  return {
    type: UPDATE_MASTER_INGREDIENTS,
    masterIngredients: json,
  };
}

// for updating a mini MI list for particular dish
export const UPDATE_MINI_MI = "UPDATE_MINI_MI";
export function updateMiniMI(data) {
  return {
    type: UPDATE_MINI_MI,
    data,
  };
}

// after the search term has been updated, update the search results
export const UPDATE_SEARCH_RESULTS_DISHES = "UPDATE_SEARCH_RESULTS_DISHES";
export function updateSearchResultsDishes() {
  return {
    type: UPDATE_SEARCH_RESULTS_DISHES,
  };
}

// after the search term has been updated, update the search results
export const UPDATE_SEARCH_RESULTS_INGREDIENTS =
  "UPDATE_SEARCH_RESULTS_INGREDIENTS";
export function updateSearchResultsIngredients() {
  return {
    type: UPDATE_SEARCH_RESULTS_INGREDIENTS,
  };
}

// update the search term in the homepage search
export const UPDATE_SEARCH_BAR_INPUT = "UPDATE_SEARCH_BAR_INPUT";
export function updateSearchBarInput(value) {
  return {
    type: UPDATE_SEARCH_BAR_INPUT,
    value,
  };
}
