// IngredientBranch.js

import React from "react";

import * as routerHelpers from "../helpers/routerHelpers";

import "../css/IngredientBranch.css";

class IngredientBranch extends React.Component {
  removeToggleListeners = (event) => {
    window.removeEventListener("mousedown", this.removeToggleListeners);
    window.removeEventListener("touchstart", this.removeToggleListeners);
    if (event.target.id !== "ingredientInfoIcon") {
      this.props.toggleIngredientInfo();
    }
  };

  toggleInfo = () => {
    if (!this.props.showIngredientInfo) {
      window.addEventListener("mousedown", this.removeToggleListeners);
      window.addEventListener("touchstart", this.removeToggleListeners);
    }
    this.props.toggleIngredientInfo();
  };

  render = () => {
    const genMarginSyle = {
      marginLeft: `${this.props.generation * 15}px`,
    };
    let toggleButtonSpan = null;
    let nameClassesAddons = "";
    const nameSansUnspecified = this.props.name.replace(` (unspecified)`, ``);
    let printName = this.props.name.includes("(unspecified)")
      ? "unspecified"
      : this.props.name;
    printName = printName.replace("_pepper", "pepper");
    const idName = printName.replace(" ", "_");
    if (
      !this.props.highlightOverride &&
      this.props.ingredientHighlightsApplied.includes(nameSansUnspecified)
    ) {
      nameClassesAddons += " highlight";
    }
    if (this.props.children.length > 0) {
      if (this.props.showChildren && this.props.showChildren[this.props.name]) {
        toggleButtonSpan = <span className="material-icons">expand_less</span>;
      } else {
        toggleButtonSpan = <span className="material-icons">expand_more</span>;
      }
      nameClassesAddons += " clickableName";
    }
    let minusFilterButton = null;
    let plusFilterButton = null;
    // only show filter buttons if active
    if (this.props.filtersShow) {
      minusFilterButton = (
        <div
          className="filterButton"
          onClick={routerHelpers.updateFiltersInURL.bind(
            null,
            `no_${this.props.name}`,
            this.props.history,
            this.props.ingredientFilters,
            this.props.ingredientHighlights
          )}
        >
          <span className="material-icons">remove</span>
        </div>
      );
      plusFilterButton = (
        <div
          className="filterButton"
          onClick={routerHelpers.updateFiltersInURL.bind(
            null,
            `yes_${this.props.name}`,
            this.props.history,
            this.props.ingredientFilters,
            this.props.ingredientHighlights
          )}
        >
          <span className="material-icons">add</span>
        </div>
      );
      if (this.props.ingredientFilters.includes(`yes_${this.props.name}`)) {
        plusFilterButton = (
          <div
            className="filterButton"
            onClick={routerHelpers.updateFiltersInURL.bind(
              null,
              `yes_${this.props.name}`,
              this.props.history,
              this.props.ingredientFilters,
              this.props.ingredientHighlights
            )}
          >
            <span className="material-icons">done</span>
          </div>
        );
      }
      if (this.props.name.includes("unspecified")) {
        minusFilterButton = null;
        plusFilterButton = null;
      }
    }

    const toggleButton = (
      <div
        className="toggleButton"
        onClick={this.props.toggleChildren.bind(
          null,
          this.props.name,
          this.props.compKey
        )}
      >
        {toggleButtonSpan}
      </div>
    );

    const name = (
      <div
        className="name"
        onClick={this.props.toggleChildren.bind(
          null,
          this.props.name,
          this.props.compKey
        )}
      >
        {printName}
      </div>
    );

    const infoButton = this.props.first ? (
      <span
        id="ingredientInfoIcon"
        className="material-icons"
        onClick={this.toggleInfo}
      >
        info_outline
      </span>
    ) : null;

    const percentage = (
      <div
        className="percentage"
        onClick={this.props.toggleChildren.bind(
          null,
          this.props.name,
          this.props.compKey
        )}
      >
        {this.props.percentage}%
      </div>
    );

    const filterButtons = this.props.filtersShow ? (
      <div className="filterButtons">
        {minusFilterButton}
        {plusFilterButton}
      </div>
    ) : null;

    return (
      <div id={idName}>
        <div
          className={`ingredientLine gen_${this.props.generation}`}
          style={genMarginSyle}
        >
          {toggleButton}
          <div className="ingredientLineFlexWrapper">
            <div className={"nameAndPercent" + nameClassesAddons}>
              <div className="nameContainer">{name}</div>
              <div style={{"display": "flex"}}>
                {infoButton}
                {percentage}
              </div>
            </div>
            {filterButtons}
          </div>
        </div>
        {this.props.showChildren &&
          this.props.showChildren[this.props.name] &&
          this.props.children}
      </div>
    );
  };
}

export default IngredientBranch;
