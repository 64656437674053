// DishesThatInclude.js
// css in Home.css

import React from "react";
import { Link } from "react-router-dom";

const DishesThatInclude = (props) => {
  const ingredients = props.main.existingSearchTerms || [];
  const dishesWith = props.main.dishesWithIngredient || [];

  const calcPercentage = (decimal) => {
    const hundo = decimal * 100;
    if (hundo === 100) {
      return hundo;
    } else {
      return hundo.toFixed(1);
    }
  };

  const renderDish = (value, key) => {
    const linkName = value.name.replace(/\s/g, "-");
    const filterIngArr = ingredients.map((val) => val); // what is this?
    const filterIngredients = filterIngArr.join("+");
    const linkTo = `/dishes/${linkName}?highlight=${filterIngredients}`;
    return (
      <div key={key} className="dishesThatInclude">
        <Link to={linkTo} className="action">
          {value.name}
        </Link>
        <div className="searchTerms">{calcPercentage(value.totalScore)}%</div>
      </div>
    );
  };

  const removeToggleListeners = (event) => {
    window.removeEventListener("mousedown", removeToggleListeners);
    window.removeEventListener("touchstart", removeToggleListeners);
    if (event.target.id !== "dishOptionsInfoIcon") {
      props.toggleDishOptionsInfo();
    }
  };

  const toggleInfo = () => {
    if (!props.main.showDishOptionsInfo) {
      window.addEventListener("mousedown", removeToggleListeners);
      window.addEventListener("touchstart", removeToggleListeners);
    }
    props.toggleDishOptionsInfo();
  };

  if (ingredients.length && dishesWith.length) {
    return (
      <div className="dishOptions">
        <div id="dishOptionsHeader">
          <h3>You could make:</h3>
          <span
            id="dishOptionsInfoIcon"
            className="material-icons"
            onClick={toggleInfo}
          >
            info_outline
          </span>
        </div>
        {props.main.showDishOptionsInfo && (
          <div id="dishOptionsInfoText">
            % of recipes that include selected ingredients
          </div>
        )}
        {dishesWith.map(renderDish)}
      </div>
    );
  } else {
    // console.log("DishesThatInclude returning null.");
    return null;
  }
};

export default DishesThatInclude;

// % of recipes that include selected ingredients
