// FilterStatusBox.js
import React from "react";
import * as routerHelpers from "../helpers/routerHelpers.js";

import "../css/FilterStatusBox.css";

const FilterStatusBox = (props) => {
  // const includeFilters = props.ingredientFilters.filter((f) =>
  //   f.includes("yes_")
  // );
  // const excludeFilters = props.ingredientFilters.filter((f) =>
  //   f.includes("no_")
  // );


  const clearAllHTML =
    props.ingredientFilters.length > 1 ? (
      <div
        id="ingFiltersClearAll"
        className="listItem"
        onClick={routerHelpers.clearFiltersInURL.bind(null, props.history, props.ingredientHighlights)}
      >
        reset all
      </div>
    ) : null;

  // const includeClear = excludeFilters.length > 0 ? null : clearAllHTML;
  // const excludeClear = excludeFilters.length > 0 ? clearAllHTML : null;

  const removeFilter = (val) => {
    console.log(`removing filter: ${val}`);
    routerHelpers.updateFiltersInURL(
      val,
      props.history,
      props.ingredientFilters,
      props.ingredientHighlights
    );
  };

  const renderFilter = (val, key) => {
    let plusMinus = "+";
    let includeExcludeClass = " includeItem";
    if(val.includes("no_")) {
      plusMinus = "-";
      includeExcludeClass = " excludeItem";
    }
    const splitVal = val.split("_");
    const name = splitVal[splitVal.length - 1];
    return (
      <div key={key} className={"listItem" + includeExcludeClass} >
        {plusMinus}{name}{" "}
        <div className="removeButton" onClick={removeFilter.bind(null, val)}>
          <span className="material-icons">close</span>
        </div>
      </div>
    );
  };

  // const renderExclude = (val, key) => {
  //   return (
  //     <div key={key} className="listItem excludeItem">
  //       -{val.slice(3)}{" "}
  //       <div className="removeButton" onClick={removeFilter.bind(null, val)}>
  //         <span className="material-icons">close</span>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div id="filterStatusBox">
      {props.ingredientFilters.length > 0 && 
        <div className="list">
          {props.ingredientFilters.map(renderFilter)}
          {clearAllHTML}
        </div>
      }
      {/* {includeFilters.length > 0 && (
        <div className="list">
          {includeFilters.map(renderInclude)}
          {includeClear}
        </div>
      )}
      {excludeFilters.length > 0 && (
        <div className="list">
          {excludeFilters.map(renderExclude)}
          {excludeClear}
        </div>
      )} */}
    </div>
  );
};

export default FilterStatusBox;
