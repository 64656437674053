// HomeSearchResults.js

import React from "react";

import "../css/HomeSearchResults.css";

const HomeSearchResults = (props) => {
  const addIngredientToSearch = (value) => {
    console.log(`adding search term: ${value}`);
    const encodedVal = encodeURIComponent(value);
    const searchTerms = props.existingSearchTerms || [];
    const newSearchTerms = searchTerms.concat([encodedVal]);
    const newURL = `/search?q=${newSearchTerms.join("+")}`;
    props.history.push(newURL);
  };

  const handleDishLink = (value) => {
    const linkName = value.replace(/\s/g, "-");
    const linkTo = `/dishes/${linkName}`;
    props.history.push(linkTo);
  };

  const renderDishResult = (value, key) => {
    if (value === "_NOMATCHES") {
      return notFoundMsg(key, "soup");
    } else {
      // const linkName = value.replace(/\s/g, "-");
      // const linkTo = `/dishes/${linkName}`;
      let dishResultClasses = "homeSearchResult";
      if (key === props.highlightedIndex - 1) {
        dishResultClasses += " homeHighlightedSuggestion";
      }
      return (
        <div
          key={key}
          className={dishResultClasses}
          onClick={handleDishLink.bind(null, value)}
        >
          {/* <Link to={linkTo} className="linkOverride"> */}
          {value}
          {/* </Link> */}
        </div>
      );
    }
  };

  const renderIngredientResult = (value, key) => {
    if (value === "_NOMATCHES") {
      return notFoundMsg(key, "ingredient");
    } else {
      let ingredientResultClasses = "homeSearchResult";
      if (key === props.highlightedIndex - 1) {
        ingredientResultClasses += " homeHighlightedSuggestion";
      }
      return (
        <div
          className={ingredientResultClasses}
          key={key}
          onClick={addIngredientToSearch.bind(null, value)}
        >
          {value}
        </div>
      );
    }
  };

  // Callback function for the mouse click and touchstart
  // listeners. Closes the search results, removes event listeners.
  const clickOutOfResults = (event) => {
    window.removeEventListener("mousedown", clickOutOfResults);
    window.removeEventListener("touchstart", clickOutOfResults);
    if (
      !(
        event.target.id === "searchHeaderIngredients" ||
        event.target.id === "searchHeaderSoups" ||
        event.target.id === "homeSearchBarInput" ||
        event.target.className.includes("homeSearchResult") ||
        event.target.className.includes("linkOverride")
      )
    ) {
      props.toggleSearchResults(false);
    }
  };

  window.addEventListener("mousedown", clickOutOfResults);
  window.addEventListener("touchstart", clickOutOfResults);

  let dishesTabClass = "searchCategory";
  let ingredientsTabClass = "searchCategory";
  if (props.selectedHomeSearchResults === "DISHES") {
    ingredientsTabClass += " inactiveRight";
  }
  if (props.selectedHomeSearchResults === "INGREDIENTS") {
    dishesTabClass += " inactiveLeft";
  }

  if (props.dishes.length === 0 || props.dishes === undefined) {
    dishesTabClass += " noResults";
  }
  if (props.ingredients.length === 0 || props.ingredients === undefined) {
    ingredientsTabClass += " noResults";
  }

  const soupsHeader = (
    <div
      id="searchHeaderSoups"
      className={dishesTabClass}
      onClick={props.selectHomeSearchResults.bind(null, "DISHES")}
    >
      SOUPS
    </div>
  );

  const ingredientsHeader = (
    <div
      id="searchHeaderIngredients"
      className={ingredientsTabClass}
      onClick={props.selectHomeSearchResults.bind(null, "INGREDIENTS")}
    >
      INGREDIENTS
    </div>
  );

  const notFoundMsg = (key, category) => {
    let notFoundClasses = "notFoundMsg";
    if (key === props.highlightedIndex - 1) {
      notFoundClasses += " homeHighlightedSuggestion";
    }
    return (
      <div key="key" className={notFoundClasses}>
        {`no ${category} results found for "${props.searchBarInput}"`}
      </div>
    );
  };

  const dishesList =
    props.dishes && props.dishes.length > 0
      ? props.dishes.map(renderDishResult)
      : notFoundMsg("soup");

  const ingredientsList =
    props.ingredients && props.ingredients.length > 0
      ? props.ingredients.map(renderIngredientResult)
      : notFoundMsg("ingredient");

  return (
    <div id="homeSearchResultsContainer">
      <div id="homeSearchResults" style={props.homeSearchWidth}>
        <div id="homeSearchResultsHeader">
          {soupsHeader}
          {ingredientsHeader}
        </div>
        <div id="searchResultsLists">
          {props.selectedHomeSearchResults === "DISHES" && dishesList}
          {props.selectedHomeSearchResults === "INGREDIENTS" && ingredientsList}
        </div>
      </div>
    </div>
  );
};

export default HomeSearchResults;
