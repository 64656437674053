// treeHelpers.js

// Use the recipe list and the parents table to build
// a new ingredient tree.
export const buildTree = (recipes, parents) => {
  // console.log(`building tree with ${recipes.length} recipes.`);
  const eList = buildEditingList(recipes, parents);
  const tree = buildIngredientTree(eList, recipes.length);
  addTagToFirst(tree);
  return tree;
};

// add a tag to the first ingredient in each component
const addTagToFirst = (tree) => {
  for (const compKey in tree) {
    const key = Object.keys(tree[compKey][0]);
    tree[compKey][0][key].first = true;
  }
};

const buildEditingList = (recipes, parents) => {
  const results = {};
  const finalResults = {};
  // get ingredients from recipes
  recipes.forEach((r) => {
    for (const comp in r.ings) {
      if (results[comp] === undefined) {
        results[comp] = {};
      }
      r.ings[comp].forEach((item) => {
        if (results[comp][item.name] === undefined) {
          results[comp][item.name] = {
            parent: parents[comp][item.name],
            recipes: [r.id],
          };
        } else {
          results[comp][item.name].recipes.push(r.id);
        }
      });
    }
  });
  // console.log(`midway eList results:`);
  // console.log(results);
  // add ingredients from parents that don't have recipes
  // for (const comp in results) {
  //   for (const ing in results[comp]) {
  //     const parent = results[comp][ing].parent;
  //     if (parent !== undefined && results[comp][parent] === undefined) {
  //       results[comp][parent] = {
  //         parent: parents[comp][parent],
  //         recipes: [],
  //       };
  //     }
  //   }
  // }
  for (const comp in results) {
    for (const child in parents[comp]) {
      const parent = parents[comp][child];
      if (results[comp][parent] === undefined) {
        results[comp][parent] = {
          parent: parents[comp][parent],
          recipes: [],
        };
      }
    }
  }
  // convert format to arrays within an object
  for (const comp in results) {
    finalResults[comp] = [];
    for (const name in results[comp]) {
      const data = results[comp][name];
      finalResults[comp].push({
        name: name,
        parent: data.parent,
        recipes: data.recipes,
      });
    }
  }
  // console.log("eList: ");
  // console.log(finalResults);
  return finalResults;
};

// Build the end-user ingredient tree without recursion, from this example:
// https://stackoverflow.com/questions/2915748/convert-a-series-of-parent-child-relationships-into-a-hierarchical-tree

// build the parent-down tree for all the components
export const buildIngredientTree = (editingList, numTotalRecipes) => {
  const tree = {};
  for (const comp in editingList) {
    tree[comp] = buildComponentTree(editingList[comp], numTotalRecipes);
    tree[comp].forEach((ing) => {
      calcTreePercentages(ing, numTotalRecipes);
    });
    tree[comp].sort(comparePercentages);
  }
  // console.log(tree);
  return tree;
};

// sort by percentages, or alphabetical if same percentage
const comparePercentages = (a, b) => {
  const aKey = Object.keys(a)[0];
  const bKey = Object.keys(b)[0];
  if (a[aKey].percentage !== b[bKey].percentage) {
    // console.log(`${a.name} - ${a.percentage} : ${b.name} - ${b.percentage}`);
    return b[bKey].percentage - a[aKey].percentage;
  } else {
    // console.log(`sorting by name: ${a.name} : ${b.name}`);
    if (a[aKey].name < b[bKey].name) {
      return -1;
    }
    if (a[aKey].name > b[bKey].name) {
      return 1;
    }
    // names must be equal
    return 0;
  }
};

/* Result tree should be in format:
  [
    {
      ingID: {
        allDecendants: [],
        children: [
          ingID: {
            allDecendants: [],
            children: [],
            percentage: Number,
            recipes: [],
            unspecified: Boolean
          }
        ],
        percentage: Number,
        recipes: [],
        unspecified: Boolean
      }
    }

  ]
*/
const buildComponentTree = (editingList) => {
  const flat = {};
  const tree = [];
  editingList.forEach((ing) => {
    // make a copy of the ingredient to avoid mutating the original.
    const ingCopy = {
      name: ing.name,
      parent: ing.parent,
      recipes: [].concat(ing.recipes),
    };
    const childName = ingCopy.name;
    const parentName = ing.parent;
    if (flat[childName] === undefined) {
      flat[childName] = {
        children: [],
        name: ingCopy.name,
      };
    }
    if (ingCopy.recipes.length > 0) {
      flat[childName].recipes = [].concat(ingCopy.recipes);
    }
    if (parentName !== undefined) {
      if (flat[parentName] === undefined) {
        flat[parentName] = {
          children: [],
          name: parentName,
          recipes: [],
        };
      }
      flat[parentName].children.push({ [childName]: flat[childName] });
    } else {
      tree.push({ [childName]: flat[childName] });
    }
  });
  // add unspecified
  // console.log(`flat:`);
  // console.log(flat);
  for (const name in flat) {
    // console.log(`name:`);
    // console.log(name);
    if (flat[name].children.length > 0 && flat[name].recipes.length > 0) {
      const unspecifiedName = `${flat[name].name} (unspecified)`;
      const tempUnspecified = {
        children: [],
        name: unspecifiedName,
        recipes: [].concat(flat[name].recipes),
      };
      flat[name].children.push({ [name]: tempUnspecified });
    }
  }
  // console.log("flat:");
  // console.log(flat);
  // console.log("tree:");
  // console.log(tree);
  return tree;
};

/*
  Traverse the basic parent-children tree and figure out
  allDecendants and percentages.
*/
const calcTreePercentages = (tree, numTotalRecipes) => {
  const key = Object.keys(tree)[0];
  tree[key].allDecendants = [].concat(tree[key].recipes);
  tree[key].children.forEach((child) => {
    tree[key].allDecendants = tree[key].allDecendants.concat(
      calcTreePercentages(child, numTotalRecipes)
    );
  });
  tree[key].allDecendants = [...new Set(tree[key].allDecendants)];
  tree[key].percentage = tree[key].allDecendants.length / numTotalRecipes;
  tree[key].children.sort(comparePercentages);
  return tree[key].allDecendants;
};

/* Get all the children of an igredient, 
and add to flat array. */
const addChildren = (name, parents, results) => {
  for (const key in parents) {
    for (const ingName in parents[key]) {
      if (parents[key][ingName] === name) {
        results.push(ingName);
        results = addChildren(ingName, parents, results);
      }
    }
  }
  return results;
};

/* Parse the ingredient filters, iterate through recipes,
and return only recipes that pass the filters. */
export const applyIngredientFilters = (recipes, parents, filters) => {
  // const startTime = Date.now();
  const filteredRecipes = [];
  let filterTerms = {
    yes: [],
    no: [],
  };
  filters.forEach((ing) => {
    const filterType = ing.split("_")[0];
    const name = ing.slice(filterType.length + 1);
    let ingFamily = [name];
    ingFamily = addChildren(name, parents, ingFamily);
    filterTerms[filterType].push(ingFamily);
  });
  // console.log(`filterTerms:`);
  // console.log(filterTerms);
  // flatten exclude filters
  const flatExclude = [];
  filterTerms.no.forEach((family) => {
    family.forEach((ing) => {
      flatExclude.push(ing);
    });
  });
  // iterate through all recipes
  for (let j = 0; j < recipes.length; j++) {
    let addRecipe = true;
    const r = recipes[j];
    // combine all component ingredients
    const allIngs = [];
    for (const comp in r.ings) {
      r.ings[comp].forEach((ing) => {
        allIngs.push(ing.name);
      });
    }
    // search through include ingredients (yes_)
    for (let j = 0; j < filterTerms.yes.length; j++) {
      const fam = filterTerms.yes[j];
      let famFound = false;
      for (let k = 0; k < fam.length; k++) {
        if (allIngs.includes(fam[k])) {
          famFound = true;
        }
      }
      if (famFound === false) {
        addRecipe = false;
        break;
      }
    }
    // if still a potential recipe, search through exclude ingredients (no_)
    for (let m = 0; m < flatExclude.length; m++) {
      if (allIngs.includes(flatExclude[m])) {
        addRecipe = false;
        break;
      }
    }
    if (addRecipe) {
      filteredRecipes.push(r);
    }
  }
  // console.log(`applyIngredientFilters finished in ${Date.now() - startTime}ms.`)
  return filteredRecipes;
};

// Iterate through an array recipes and return an array of recipes
// that match the applied source filters.
export const applySourceFilters = (recipes, allSources) => {
  const filteredRecipes = recipes.filter((r) => {
    return allSources[r.source].show;
  });
  return filteredRecipes;
};

// Take the list of filtered workingRecipes and
// build a new parents list.
// export const buildWorkingParents = (recipes, parents) => {
//   const results = {};
//   const allIngs = {};
//   for (const comp in parents) {
//     allIngs[comp] = [];
//     results[comp] = {};
//   }
//   recipes.forEach((r) => {
//     for (const comp in r.ings) {
//       r.ings[comp].forEach((ing) => {
//         if (results[comp][ing.name] === undefined) {
//           results[comp][ing.name] = parents[comp][ing.name];
//         }
//       });
//     }
//   });
//   console.log(`workingParents:`);
//   console.log(results);
//   return results;
// };

// Take the list of filtered workingRecipes and the original parents
// list and return a new parents list for just workingRecipes.
export const buildWorkingParents = (recipes, parents) => {
  const results = {};
  const includedIngs = {};
  const tempParents = {};
  const workingParents = {};
  for (const comp in parents) {
    results[comp] = {};
    includedIngs[comp] = [];
    tempParents[comp] = {};
    workingParents[comp] = {};
  }
  recipes.forEach((r) => {
    for (const comp in r.ings) {
      r.ings[comp].forEach((ing) => {
        if (!includedIngs[comp].includes(ing.name)) {
          includedIngs[comp].push(ing.name);
        }
      });
    }
  });
  // console.log(`includedIngs:`);
  // console.log(includedIngs);
  for (const comp in includedIngs) {
    includedIngs[comp].forEach((ing) => {
      if (tempParents[comp][ing] === undefined) {
        tempParents[comp][ing] = [];
      }
      tempParents[comp][ing][0] = [ing];
      let nextParent = parents[comp][ing];
      let index = 1;
      while (nextParent !== undefined) {
        if (tempParents[comp][nextParent] === undefined) {
          tempParents[comp][nextParent] = [];
        }
        if (tempParents[comp][nextParent][index] === undefined) {
          tempParents[comp][nextParent][index] = [ing];
        } else {
          tempParents[comp][nextParent][index].push(ing);
        }
        nextParent = parents[comp][nextParent];
        index += 1;
      }
    });
  }
  // console.log(`tempParents:`);
  // console.log(tempParents);

  for (const comp in tempParents) {
    const orderedKeys = Object.keys(tempParents[comp]).sort((aKey, bKey) => {
      return tempParents[comp][aKey].length - tempParents[comp][bKey].length;
    });
    // console.log(`orderedKeys:`);
    // console.log(orderedKeys);
    orderedKeys.forEach((parent) => {
      const parentArr = tempParents[comp][parent];
      if (parentArr[1] !== undefined) {
        if (parentArr[0] !== undefined || parentArr[1].length > 1) {
          parentArr[1].forEach((child) => {
            workingParents[comp][child] = parent;
          });
          // parent is now definitely in the tree, so hoist to its parent
          const parentParent = parents[comp][parent];
          if (tempParents[comp][parentParent] === undefined) {
            tempParents[comp][parentParent] = [];
          }
          if (tempParents[comp][parentParent][1] === undefined) {
            tempParents[comp][parentParent][1] = [parent];
          } else {
            if (!tempParents[comp][parentParent][1].includes(parent)) {
              tempParents[comp][parentParent][1].push(parent);
            }
          }
        } else {
          // only one direct child, so maybe need to hoist decendants up a generation
          const grandparent = parents[comp][parent];
          if (tempParents[comp][grandparent] === undefined) {
            tempParents[comp][grandparent] = [];
          }
          parentArr.forEach((subArr, i) => {
            subArr.forEach((ing) => {
              if (tempParents[comp][grandparent][i] === undefined) {
                tempParents[comp][grandparent][i] = [];
              }
              if (!tempParents[comp][grandparent][i].includes(ing)) {
                tempParents[comp][grandparent][i].push(ing);
              }
            });
          });
        }
      }
    });
  }
  // console.log(`workingParents:`);
  // console.log(workingParents);
  return workingParents;
  // return parents;
};

// Take the array of highlight ingredients and build a new
// array that includes those ingredients, plus all children.
export const calcAllHighlights = (highlights, parents) => {
  const startTime = Date.now();
  const allHighlights = [].concat(highlights);
  let foundNew = true;
  while (foundNew === true) {
    foundNew = false;
    for (const comp in parents) {
      for (const ing in parents[comp]) {
        if (!allHighlights.includes(ing)) {
          if (allHighlights.includes(parents[comp][ing])) {
            allHighlights.push(ing);
            foundNew = true;
          }
        }
      }
    }
  }
  console.log(`calcAllHighlights completed in ${Date.now() - startTime}ms.`);
  // console.log(`allHighlights:`, allHighlights);
  return allHighlights;
};

// for each component in a tree, send to
// flattenTreeComponent function.
export const flattenTree = (tree) => {
  const results = {};
  for (const comp in tree) {
    results[comp] = flattenTreeComponent(tree[comp]);
  }
  return results;
};

// build an array of all ingredients in a tree component,
// ordered by their location in the tree.
const flattenTreeComponent = (tree) => {
  let results = [];
  // console.log("tree:")
  // console.log(tree)
  tree.forEach((branch) => {
    const key = Object.keys(branch)[0];
    const name = branch[key].name
      .replace(" (unspecified)", "")
      .replace("_", "");
    results.push(name);
    if (branch[key].children.length > 0) {
      results = results.concat(flattenTreeComponent(branch[key].children));
    }
  });
  return results;
};
