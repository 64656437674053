// firebaseHelpers.js

import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

import { getAnalytics } from "firebase/analytics";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore";
import firebaseConfigs from "./firebaseConfig";
import * as actions from "../actions/actionCreators";

// 'siftedproduction' database
// Get the pre-built commonly paired ingredients results.
export const fetchCommonlyPaired = async (dispatch, app, searchTerms) => {
  // console.log(`searchTerms:`);
  // console.log(searchTerms);
  const primaryResults = {};
  const db = getFirestore(app);
  for (const term of searchTerms) {
    const docRef = doc(db, "commonlyPaired", term, "data", "primary");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      primaryResults[term] = docSnap.data().data;
    } else {
      console.log(`no primary commonlyPaired data found for: ${term}`);
    }
    // FIREBASE SDK V7 - UPDATE TO V9 15MAR2022
    // await db
    //   .collection("commonlyPaired")
    //   .doc(term)
    //   .collection("data")
    //   .doc("primary")
    //   .get()
    //   .then((doc) => {
    //     if (doc.exists) {
    //       primaryResults[term] = doc.data().data;
    //     } else {
    //       console.log(`no commonlyPaired data found for ${term}!!`);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("ERROR: firebase error in fetchCommonlyPaired - primary");
    //     console.log(err);
    //   });
  }
  dispatch(actions.updateCommonlyPairedPrimary(primaryResults));
  const secondaryResults = {};
  for (const term of searchTerms) {
    const docRef = doc(db, "commonlyPaired", term, "data", "secondary");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      secondaryResults[term] = {};
      secondaryResults[term].children = docSnap.data().children;
      secondaryResults[term].neverPair = docSnap.data().neverPair;
      secondaryResults[term].recipes = docSnap.data().recipes;
    } else {
      console.log(`no secondary commonlyPaired data found for: ${term}`);
    }

    // FIREBASE SDK V7 - UPDATE TO V9 15MAR2022
    // await db
    //   .collection("commonlyPaired")
    //   .doc(term)
    //   .collection("data")
    //   .doc("secondary")
    //   .get()
    //   .then((doc) => {
    //     if (doc.exists) {
    //       secondaryResults[term] = {};
    //       secondaryResults[term].children = doc.data().children;
    //       secondaryResults[term].neverPair = doc.data().neverPair;
    //       secondaryResults[term].recipes = doc.data().recipes;
    //     } else {
    //       console.log(`no commonlyPaired data found for ${term}!!`);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("ERROR: firebase error in fetchCommonlyPaired - secondary");
    //     console.log(err);
    //   });
  }
  dispatch(actions.updateCommonlyPairedSecondary(secondaryResults));
};

export const getDish = async (dispatch, app, dishName) => {
  const compressedData = {};
  const db = getFirestore(app);
  const collectionRef = collection(db, "dishes", dishName, "primary");
  const querySnapshot = await getDocs(collectionRef);
  querySnapshot.forEach((docSnap) => {
    const data = docSnap.data();
    const dataName = Object.keys(data)[0];
    compressedData[dataName] = data[dataName];
  });
  dispatch(actions.updateDishCompressed(dishName, compressedData));
  // FIREBASE SDK V7 - UPDATE TO V9 15MAR2022
  // await db
  //   .collection("dishes")
  //   .doc(dishName)
  //   .collection("primary")
  //   .get()
  //   .then((snapshot) => {
  //     snapshot.forEach((doc) => {
  //       const data = doc.data();
  //       const dataName = Object.keys(data)[0];
  //       compressedData[dataName] = data[dataName];
  //     });
  //   })
  //   .catch((err) => {
  //     console.log(`Firebase ERROR fetching dish: ${dishName}`);
  //     console.log(err);
  //   });
};

export const getDishList = async (dispatch, app) => {
  const db = getFirestore(app);
  const docRef = doc(db, "metadata", "dishes_dashboarded");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const dishes = docSnap.data().dishes;
    const formattedDishes = [];
    dishes.sort(); // ensure they're in alphabetical order
    dishes.forEach((name) => {
      formattedDishes.push(name.replace(/_/g, " "));
    });
    dispatch(actions.updateDishList(formattedDishes));
  } else {
    console.log("ERROR: unable to retrieve database metadata.");
  }
  // FIREBASE SDK V7 - UPDATE TO V9 15MAR2022
  // db.collection("metadata")
  //   .doc("dishes_dashboarded")
  //   .get()
  //   .then((doc) => {
  //     const dishes = doc.data().dishes;
  //     const formattedDishes = [];
  //     dishes.sort(); // ensure they're in alphabetical order
  //     dishes.forEach((name) => {
  //       formattedDishes.push(name.replace(/_/g, " "));
  //     });
  //     dispatch(actions.updateDishList(formattedDishes));
  //   })
  //   .catch((err) => {
  //     console.log(`Firebase ERROR fetching dishes_dashboarded. `);
  //     console.log(err);
  //   });
};

// Get the Master Ingredients list.
export const getMasterIngredients = async (dispatch, app) => {
  const startTime = Date.now();
  const db = getFirestore(app);
  const querySnapshot = await getDocs(collection(db, "ingredients"));
  const masterList = {};
  querySnapshot.forEach((doc) => {
    masterList[doc.id] = doc.data();
  });
  console.log(`getMasterIngredients finished in ${Date.now() - startTime}`);
  dispatch(actions.updateMasterIngredients(masterList));
  // FIREBASE SDK V7 - UPDATE TO V9 15MAR2022
  // const MIpromise = db
  //   .collection("ingredients")
  //   .get()
  //   .then(
  //     (snapshot) => {
  //       var masterList = {};
  //       snapshot.forEach((doc) => {
  //         masterList[doc.id] = doc.data();
  //       });
  //       console.log(
  //         `getMasterIngredients finished in ${Date.now() - startTime}`
  //       );
  //       dispatch(actions.updateMasterIngredients(masterList));
  //     },
  //     function(error) {
  //       console.log(`Firebase ERROR fetching master ingredients.`);
  //       console.log(error);
  //     }
  //   );
  // console.log(`MIpromise: `, MIpromise);
  return masterList;
};

// Retrieve a mini MI list for a specific dish.
// 15 MAR 2022 - Not used.  Remove?
export const getMiniMI = (dispatch, db, dishName) => {
  db.collection("dishes")
    .doc(dishName)
    .collection("secondary")
    .doc("miniMI")
    .get()
    .then((doc) => {
      dispatch(actions.updateMiniMI(doc.data()));
    })
    .catch((err) => {
      console.log(`Firebase ERROR fetching miniMI for: ${dishName}`);
      console.log(err);
    });
};

// Initialize a Firebase app DB, and start Google Analytics
export const initializeFirebase = (dispatch, dbName, url) => {
  const config = firebaseConfigs[dbName];
  const firebaseApp = initializeApp(config, dbName);
  // const appCheck = initializeAppCheck(firebaseApp, {
  //   provider: new ReCaptchaV3Provider(firebaseConfigs.recaptchaSiteKey),
  //   isTokenAutoRefreshEnabled: true,
  // });
  // check NODE_ENV environment variable and use debug token if
  // running in "development" (localhost).
  if (process.env.NODE_ENV === "development") {
    console.log("Current environment is 'development'.");
    window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(firebaseConfigs.recaptchaSiteKey),
    isTokenAutoRefreshEnabled: true,
  });
  // const analytics = getAnalytics(firebaseApp);
  getAnalytics(firebaseApp);
  // console.log("analytics:");
  // console.log(analytics);
  dispatch(actions.addFirebase(firebaseApp));
  return firebaseApp;
};

// Get the pre-built search results of dishes by ingredient.
export const searchDishesByIngredient = async (dispatch, app, searchTerms) => {
  const results = {};
  const foundTerms = [];
  const startTime = Date.now();
  const searchTermsCopy = [].concat(searchTerms);
  const sortedSearchTerms = searchTermsCopy.sort((a, b) => a.localeCompare(b));
  const ANDsearchString = sortedSearchTerms.join(" AND ");
  const db = getFirestore(app);
  const docRef = doc(db, "ingredientsSearch", ANDsearchString);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    results[ANDsearchString] = docSnap.data().dishes;
    foundTerms.push(ANDsearchString);
  } else {
    const ingsRef = collection(db, "ingredientsSearchRecipes");
    const queryArgs = [ingsRef];
    sortedSearchTerms.forEach((term, i) => {
      // limit to ten - max allowed by firebase
      if (i < 10) {
        queryArgs.push(where(`ingsAndParents.${term}`, "==", true));
      }
    });
    const q = query.apply(null, queryArgs);
    const querySnapshot = await getDocs(q);
    results.recipes = [];
    querySnapshot.forEach((docSnap) => {
      results.recipes.push({ [docSnap.id]: docSnap.data() });
    });
    Array.prototype.push.apply(foundTerms, searchTerms);
  }
  // FIREBASE SDK V7 - UPDATE TO V9 15MAR2022
  // await db
  //   .collection("ingredientsSearch")
  //   .doc(ANDsearchString)
  //   .get()
  //   .then((doc) => {
  //     if (doc.exists) {
  //       results[ANDsearchString] = doc.data().dishes;
  //       foundTerms.push(ANDsearchString);
  //     } else {
  //       let query = db.collection("ingredientsSearchRecipes");
  //       sortedSearchTerms.forEach((term, i) => {
  //         // limit to ten - max allowed by firebase
  //         if (i < 10) {
  //           query = query.where(`ingsAndParents.${term}`, "==", true);
  //         }
  //       });
  //       return query
  //         .get()
  //         .then((snapshot) => {
  //           results.recipes = [];
  //           snapshot.forEach((doc) => {
  //             results.recipes.push({ [doc.id]: doc.data() });
  //           });
  //           Array.prototype.push.apply(foundTerms, searchTerms);
  //         })
  //         .catch((err) => console.log(err));
  //     }
  //   })
  //   .catch((err) => console.log(err));
  console.log(`fetch time: ${Date.now() - startTime}`);
  // console.log(`results:`);
  // console.log(results);
  dispatch(actions.updateDishesWithIngredient(results, foundTerms));
};

// submit the contact form data to the database
export const submitContactForm = async (dispatch, app, email, msg) => {
  const db = getFirestore(app);
  const docRef = await addDoc(collection(db, "contactMessages"), {
    email: email,
    msg: msg,
    timestamp: serverTimestamp(),
  });
  console.log(`contact message doc written with ID: ${docRef.id}`);
  dispatch(actions.updateConfirmMsg("SUCCESS"));
  // .catch((err) => {
  //   console.log(err);
  //   dispatch(actions.updateConfirmMsg("ERROR"));
};
